import { autoinject, bindable } from 'aurelia-framework';

import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { EntityGroupUtils } from 'common/EntityGrouper/EntityGroupUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { CreateProcessTaskDeviceDialog } from '../create-process-task-device-dialog/create-process-task-device-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { EditProcessTaskDeviceDialog } from '../edit-process-task-device-dialog/edit-process-task-device-dialog';
import { Dialogs } from '../../classes/Dialogs';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { CheckboxCheckedChangedEvent } from '../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { ProcessConfigurationDeviceExport } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer,
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap
} from '../../computedValues/computers/ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer';
import { ProcessConfigurationDeviceExportUtils } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/ProcessConfigurationDeviceExportUtils';
import {
  ProcessTaskDeviceGroups,
  ProcessTaskDeviceGroupsForProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';
import { FileDownloadService } from '../../services/FileDownloadService';

/**
 * @event {CreateAppointmentClickedEvent} create-appointment-clicked
 */
@autoinject()
export class ProcessTaskDevicesWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public selectedDevices: Array<ProcessTaskDevice> = [];

  /**
   * null if devices aren't loaded
   * read only
   */
  @bindable()
  public processTaskDeviceCount: number | null = null;

  @bindable()
  public deviceSelectionEnabled: boolean = false;

  @bindable()
  public hasCreateAppointmentButton: boolean = false;

  @bindable()
  public appointmentCreationEnabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  protected groupedDevices: ProcessTaskDeviceGroups = [];
  private devices: Array<ProcessTaskDevice> = [];
  private isAttached = false;
  private deviceExportsByProcessTaskGroupId: ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap =
    new Map();
  private availableProcessConfigurationDeviceExports: Array<ProcessConfigurationDeviceExport> =
    [];

  constructor(
    private readonly domElement: Element,
    private readonly fileDownloadService: FileDownloadService,
    private readonly socketService: SocketService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer,
        computeData: {},
        callback: (deviceExportsByProcessTaskGroupId) => {
          this.deviceExportsByProcessTaskGroupId =
            deviceExportsByProcessTaskGroupId;
          this.updateAvailableProcessConfigurationDeviceExports();
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessTaskDeviceGroupsForProcessTaskIdComputer,
        callback: (groupedDevices) => {
          this.groupedDevices = groupedDevices;
          this.devices = EntityGroupUtils.getAllEntities(groupedDevices);
          this.processTaskDeviceCount = this.devices.length;
          this.updateAvailableProcessConfigurationDeviceExports();
        },
        createComputeData: () => {
          return this.processTask
            ? { processTaskId: this.processTask.id }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private updateAvailableProcessConfigurationDeviceExports(): void {
    if (this.processTask) {
      this.availableProcessConfigurationDeviceExports =
        ProcessConfigurationDeviceExportUtils.getAvailableProcessConfigurationDeviceExports(
          this.devices,
          this.deviceExportsByProcessTaskGroupId.get(
            this.processTask.ownerProcessTaskGroupId
          ) ?? []
        );
    } else {
      this.availableProcessConfigurationDeviceExports = [];
    }
  }

  private handleAddDeviceClick(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ProcessTaskDevicesWidget.handleAddDeviceClick without a processTask"
    );

    void CreateProcessTaskDeviceDialog.open({
      processTask: this.processTask,
      onDialogClosed: (createdDevice) => {
        if (createdDevice) {
          this.computedValueService.recompute(
            ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer
          );
          this.goToDevice(createdDevice);
        }
      }
    });
  }

  private handleEditDeviceClicked(device: ProcessTaskDevice): void {
    void EditProcessTaskDeviceDialog.open({
      processTaskDevice: device,
      onDialogClosed: () => {
        this.goToDevice(device);
      }
    });
  }

  private handleCreateAppointmentClick(): void {
    DomEventHelper.fireEvent<CreateAppointmentClickedEvent>(this.domElement, {
      name: 'create-appointment-clicked',
      detail: null
    });
  }

  private handleDeviceCheckboxCheckedChanged(
    device: ProcessTaskDevice,
    event: CheckboxCheckedChangedEvent
  ): void {
    if (event.detail.checked) {
      ArrayUtils.pushUnique(this.selectedDevices, device);
    } else {
      ArrayUtils.remove(this.selectedDevices, device);
    }
  }

  private handleSelectAllCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    for (const device of this.devices) {
      if (event.detail.checked) {
        ArrayUtils.pushUnique(this.selectedDevices, device);
      } else {
        ArrayUtils.remove(this.selectedDevices, device);
      }
    }
  }

  private handleDownloadDevicesClick(
    config: ProcessConfigurationDeviceExport
  ): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ProcessTaskDevicesWidget.handleDownloadDevicesClick without a processTask"
    );

    Dialogs.waitDialog();
    this.socketService.downloadProcessTaskDevices(
      {
        processTaskId: this.processTask.id,
        processConfigurationDeviceExportId: config.id
      },
      (response) => {
        if (response.success && response.token) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          const errorMessageKey = `serverResponses.${
            response.status ? response.status : 'unspecifiedError'
          }`;
          void Dialogs.errorDialogTk('general.downloadError', errorMessageKey);
        }
      }
    );
  }

  private goToDevice(device: ProcessTaskDevice): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getDeviceListItemId(device.id),
      null,
      device,
      () => this.isAttached
    );
  }

  private getDeviceListItemId(deviceId: string): string {
    return 'process-task-devices-widget--device-' + deviceId;
  }

  private deviceIsSelected(
    selectedDevices: Array<ProcessTaskDevice>,
    device: ProcessTaskDevice
  ): boolean {
    return selectedDevices.includes(device);
  }

  private allDevicesSelected(
    selectedDevices: Array<ProcessTaskDevice>,
    devices: Array<ProcessTaskDevice>
  ): boolean {
    return devices.every((device) => selectedDevices.includes(device));
  }
}

export type CreateAppointmentClickedEvent = NamedCustomEvent<
  'create-appointment-clicked',
  null
>;
