import { EntityInfo } from '@record-it-npm/synchro-common';

import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';

import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskToProject } from '../../../../classes/EntityManager/entities/ProcessTaskToProject/types';
import { projectEntityInfo } from '../../../../classes/EntityManager/entities/Project/projectEntityInfo';
import { Project } from '../../../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionHelper } from '../../../../classes/PermissionHelper';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { LockedByFinishedAppointmentsProjectIdsComputer } from '../../../../computedValues/computers/LockedByFinishedAppointmentsProjectIdsComputer/LockedByFinishedAppointmentsProjectIdsComputer';

export class ProjectAdapter implements EntityAdapter<Project> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;
  private readonly currentUserService: CurrentUserService;
  private readonly entityManager: AppEntityManager;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private editableUserGroupIds: Set<string> = new Set();
  private thingSectionsSetting: boolean = false;
  private currentUser: User | null = null;

  private lockedByFinishedAppointmentsProjectIdsSet: Set<string> = new Set();

  constructor(options: ProjectAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
    this.currentUserService = options.currentUserService;
    this.entityManager = options.entityManager;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      }),

      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      }),

      this.computedValueService.subscribe({
        valueComputerClass: LockedByFinishedAppointmentsProjectIdsComputer,
        computeData: {},
        callback: (lockedByFinishedAppointmentsProjectIdsSet) => {
          this.lockedByFinishedAppointmentsProjectIdsSet =
            lockedByFinishedAppointmentsProjectIdsSet;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.thingSections',
        (thingSections) => {
          this.thingSectionsSetting = thingSections;
          updateBindings();
        }
      )
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      () => updateBindings()
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      canEditBasedOnRolePermission: (userGroupSpecificPermissions) =>
        userGroupSpecificPermissions.getCanDeleteProjects()
    });
  }

  public canEditField(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      canEditBasedOnRolePermission: (userGroupSpecificPermissions) =>
        userGroupSpecificPermissions.getCanUpdateProjects()
    });
  }

  public canEditProperties(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      canEditBasedOnRolePermission: (userGroupSpecificPermissions) =>
        userGroupSpecificPermissions.getCanUpdateProjects()
    });
  }

  public canEditPictures(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      canEditBasedOnRolePermission: (userGroupSpecificPermissions) =>
        userGroupSpecificPermissions.getCanUpdateProjects()
    });
  }

  public canMarkOnThingSections(project: Project): boolean {
    return this.entityIsEditableUserGroup(project) && this.thingSectionsSetting;
  }

  public canCreateReports(project: Project): boolean {
    return this.entityIsEditableUserGroup(project);
  }

  public canCreateEntries(project: Project): boolean {
    return this.checkProjectPermissions({
      project,
      checkRolePermission: (permissions) => permissions.getCanCreateEntries()
    });
  }

  public canDragAndDropEntries(project: Project): boolean {
    return this.checkProjectPermissions({
      project,
      checkRolePermission: (permissions) => permissions.getCanUpdateEntries()
    });
  }

  public canViewValueCalculationResults(): boolean {
    return this.currentUser
      ? PermissionHelper.userHasPermission(
          this.currentUser,
          'canViewValueCalculationResults'
        )
      : false;
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Project,
    Project
  > {
    return projectEntityInfo;
  }

  public canCopyUserDefinedEntities(project: Project): boolean {
    return this.currentUser
      ? PermissionHelper.userHasPermission(
          this.currentUser,
          'canAdministerUserDefinedEntities'
        ) && this.entityIsEditableUserGroup(project)
      : false;
  }

  private checkProjectPermission({
    project,
    canEditBasedOnRolePermission
  }: {
    project: Project;
    canEditBasedOnRolePermission: (
      userGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    const processTaskToProject =
      this.entityManager.processTaskToProjectRepository.getByProjectId(
        project.id
      )[0];

    if (
      processTaskToProject &&
      processTaskToProject.type === ProcessTaskToProjectType.FORM &&
      !this.canEditOperationsForm(processTaskToProject)
    ) {
      return false;
    }

    return canEditBasedOnRolePermission(
      this.roleBasedPermissions.inUserGroupId(project.ownerUserGroupId)
    );
  }

  private canEditOperationsForm(
    processTaskToProject: ProcessTaskToProject
  ): boolean {
    if (!processTaskToProject.processTaskAppointmentId) return true;

    const isOperationsOfficeUser = this.currentUser
      ? PermissionHelper.userHasPermission(this.currentUser, 'canUseOperations')
      : false;
    if (isOperationsOfficeUser) return true;

    const isOperationsFieldUseUser = this.currentUser
      ? PermissionHelper.userHasPermission(
          this.currentUser,
          'canUseOperationsFieldUse'
        )
      : false;
    if (
      isOperationsFieldUseUser &&
      !this.projectBelongsToFormOfFinishedAppointment(
        processTaskToProject.processTaskAppointmentId
      )
    )
      return true;
    return false;
  }

  private projectBelongsToFormOfFinishedAppointment(
    appointmentId: string
  ): boolean {
    const appointment =
      this.entityManager.processTaskAppointmentRepository.getById(
        appointmentId
      );
    if (!appointment) {
      throw new Error(`Cannot find appointment ${appointmentId}`);
    }
    if (!!appointment.finishedAt) return true;
    return false;
  }

  private entityIsEditableUserGroup(entity: Project): boolean {
    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }

  private checkProjectPermissions({
    project,
    checkRolePermission
  }: {
    project: Project;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    if (this.lockedByFinishedAppointmentsProjectIdsSet.has(project.id)) {
      return false;
    }

    return checkRolePermission(
      this.roleBasedPermissions.inUserGroupId(project.ownerUserGroupId)
    );
  }
}

export type ProjectAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
  currentUserService: CurrentUserService;
  entityManager: AppEntityManager;
};
