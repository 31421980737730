import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ProjectType,
  StructureTemplateProjectType
} from 'common/Types/Entities/Project/ProjectDto';

import { Dialogs } from '../../classes/Dialogs';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { SocketService } from '../../services/SocketService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { CopyProjectService } from '../../classes/EntityManager/entities/Project/CopyProjectService';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { computed } from '../../hooks/computed';
import { currentUser, expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';
import { EntitiesWithPermissionHandle } from '../../services/PermissionsService/EntitiesWithPermissionHandle/EntitiesWithPermissionHandle';
import { watch } from '../../hooks/watch';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class CopyProjectDialog {
  private readonly subscriptionManager: SubscriptionManager;
  private dialogViewModel: RecordItDialog | null = null;

  private loading = false;
  private isOnline = false;

  private project: Project | null = null;
  private thingOptions: Array<ThingOption> = [];

  private newName = '';
  private copyProperties = false;
  private copyPropertyValues = false;
  private copyProjectPictures = false;
  private copyGlobalProjectPictures = false;
  private copyEntries = false;
  private copyEntryPictures = false;
  private copyTags = false;
  private copyTextBricks = false;
  private copyUserDefinedEntities = false;
  private copyUserDefinedEntityPropertyValues = false;
  private onCopySuccess: ((project: Project) => void) | null = null;

  private selectedThing: Thing | null = null;

  private selectedStructureTemplate: StructureTemplate | null = null;

  @subscribableLifecycle()
  protected permissionsHandle:
    | EntityNameToPermissionsHandle[EntityName.Project]
    | null = null;

  @subscribableLifecycle()
  protected thingsWithCanCreateProjectsHandle: EntitiesWithPermissionHandle<EntityName.Thing>;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly copyProjectService: CopyProjectService,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionsHandle =
      this.permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Project,
        expression: 'project',
        context: this
      });

    this.thingsWithCanCreateProjectsHandle =
      permissionsService.getEntitiesWithPermissionHandleForPermissionName({
        entityName: EntityName.Thing,
        permissionName: 'canCreateProjects'
      });
  }

  public static async open(options: CopyProjectDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public resetValues(): void {
    this.project = null;
    this.newName = '';
    this.loading = false;
  }

  public open(options: CopyProjectDialogOptions): void {
    this.project = options.project;
    this.newName = this.i18n.tr(
      'dialogs.copyProjectDialog.newNameDefaultValue',
      {
        projectName: options.project.name ?? ''
      }
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.updateThings();

    this.copyProperties = options.copyProperties ?? false;
    this.copyPropertyValues = options.copyPropertyValues ?? false;
    this.copyProjectPictures = options.copyProjectPictures ?? false;
    this.copyGlobalProjectPictures = options.copyGlobalProjectPictures ?? false;
    this.copyEntries = options.copyEntries ?? false;
    this.copyEntryPictures = options.copyEntryPictures ?? false;
    this.copyTags = options.copyTags ?? false;
    this.copyTextBricks = options.copyTextBricks ?? false;
    this.copyUserDefinedEntities = options.copyUserDefinedEntities ?? false;
    this.copyUserDefinedEntityPropertyValues =
      options.copyUserDefinedEntityPropertyValues ?? false;

    this.onCopySuccess = options.onCopySuccess ?? null;

    this.dialogViewModel?.open();
  }

  protected handleDialogClosed(): void {
    this.resetValues();
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleCloseDialogClick(): void {
    this.dialogViewModel?.close();
  }

  protected handleCopyProjectClick(): void {
    assertNotNullOrUndefined(this.project, 'no project defined');
    assertNotNullOrUndefined(
      this.selectedThing,
      "can't CopyProjectDialog.handleCopyProjectClick without selectedThing"
    );

    this.loading = true;
    this.copyProjectService.copyProjectById(
      {
        id: this.project.id,
        newName: this.newName,
        newStructureTemplateId: this.selectedStructureTemplate?.id ?? null,
        copyProperties: this.copyProperties,
        copyPropertyValues: this.copyPropertyValues,
        copyProjectPictures: this.copyProjectPictures,
        copyGlobalProjectPictures: this.copyGlobalProjectPictures,
        copyEntries: this.copyEntries,
        copyEntryPictures: this.copyEntryPictures,
        copyTags: this.copyTags,
        copyTextBricks: this.copyTextBricks,
        copyUserDefinedEntities: this.copyUserDefinedEntities,
        copyUserDefinedEntityPropertyValues:
          this.copyUserDefinedEntityPropertyValues,
        thingId: this.selectedThing.id
      },
      (data) => {
        this.loading = false;
        this.dialogViewModel?.close();
        if (data.success) {
          Dialogs.timedSuccessDialog('Kopieren erfolgreich!');
          if (this.onCopySuccess && data.newProjectId) {
            const newProject =
              this.entityManager.projectRepository.getRequiredById(
                data.newProjectId
              );
            this.onCopySuccess(newProject);
          }
        } else {
          const errorMessageKey = data.status
            ? `serverResponses.${data.status}`
            : 'serverResponses.unspecifiedError';
          void Dialogs.errorDialog('Fehler', this.i18n.tr(errorMessageKey));
        }
      }
    );
  }

  @watch(model(EntityName.Thing))
  protected updateThings(): void {
    this.thingsWithCanCreateProjectsHandle.setEntities(
      this.entityManager.thingRepository.getAll()
    );
  }

  @watch(
    expression('project.thing'),
    expression('thingsWithCanCreateProjectsHandle.filteredEntities')
  )
  protected updateThingOptions(): void {
    this.thingOptions = InstancePreserver.createNewArray({
      originalArray: this.thingOptions,
      newArray: this.getNewThingOptions(),
      getTrackingValue: (option) => option.thing
    });

    if (
      !this.thingOptions.some((option) => option.thing === this.selectedThing)
    ) {
      const currentThingOption = this.thingOptions.find(
        (option) => option.thing.id === this.project?.thing
      );

      this.selectedThing =
        currentThingOption?.thing ?? this.thingOptions[0]?.thing ?? null;
    }
  }

  private getNewThingOptions(): Array<ThingOption> {
    return this.thingsWithCanCreateProjectsHandle.filteredEntities
      .sort((a, b) => {
        if (a.id === this.project?.thing) {
          return -1;
        }

        if (b.id === this.project?.thing) {
          return 1;
        }

        return 0;
      })
      .map<ThingOption>((thing) => {
        return {
          thing,
          label:
            thing.id === this.project?.thing
              ? this.i18n.tr('dialogs.copyProjectDialog.currentThing')
              : thing.name ?? ''
        };
      });
  }

  @computedFrom(
    'copyProperties',
    'copyPropertyValues',
    'copyProjectPictures',
    'copyGlobalProjectPictures',
    'copyEntries',
    'copyEntryPictures',
    'copyTags',
    'userCanCopyUserDefinedEntities',
    'copyUserDefinedEntities',
    'copyUserDefinedEntityPropertyValues',
    'isStructureProject',
    'copyTextBricks',
    'permissionsHandle.canCopyUserDefinedEntities'
  )
  protected get selectAll(): boolean {
    return (
      this.copyProperties &&
      this.copyPropertyValues &&
      this.copyProjectPictures &&
      this.copyGlobalProjectPictures &&
      this.copyEntries &&
      this.copyEntryPictures &&
      this.copyTags &&
      (!this.permissionsHandle?.canCopyUserDefinedEntities ||
        this.copyUserDefinedEntities) &&
      (!this.permissionsHandle?.canCopyUserDefinedEntities ||
        this.copyUserDefinedEntityPropertyValues) &&
      (!this.isStructureProject || this.copyTextBricks)
    );
  }

  protected set selectAll(newValue: boolean) {
    this.copyProperties = newValue;
    this.copyPropertyValues = newValue;
    this.copyProjectPictures = newValue;
    this.copyGlobalProjectPictures = newValue;
    this.copyEntries = newValue;
    this.copyEntryPictures = newValue;
    this.copyTags = newValue;
    this.copyTextBricks = newValue;
    this.copyUserDefinedEntities = newValue;
    this.copyUserDefinedEntityPropertyValues = newValue;
  }

  @computedFrom('project.projectType')
  protected get isStructureProject(): boolean {
    return (
      this.project?.projectType === ProjectType.B1300 ||
      this.project?.projectType === ProjectType.INSPECT
    );
  }

  @computed(currentUser(), model(EntityName.StructureTemplate))
  protected get structureTemplates(): Array<StructureTemplateSelectOption> {
    if (!this.project?.projectType || !this.isStructureProject) return [];
    const templates =
      this.entityManager.structureTemplateRepository.getActiveTemplatesByProjectType(
        this.project.projectType as StructureTemplateProjectType
      );

    const templatesWithoutCurrentTemplate = templates.filter(
      (t) => this.project?.structureTemplateId !== t.id
    );

    return templatesWithoutCurrentTemplate.map((t) => {
      return { label: t.name ?? '', structureTemplate: t };
    });
  }
}

type CopyProjectDialogOptions = {
  project: Project;
  copyProperties?: boolean;
  copyPropertyValues?: boolean;
  copyProjectPictures?: boolean;
  copyGlobalProjectPictures?: boolean;
  copyEntries?: boolean;
  copyEntryPictures?: boolean;
  copyTags?: boolean;
  copyTextBricks?: boolean;
  copyUserDefinedEntities?: boolean;
  copyUserDefinedEntityPropertyValues?: boolean;
  newStructureTemplateId?: string | null;

  onCopySuccess?: ((project: Project) => void) | null;
};

type StructureTemplateSelectOption = {
  label: string;
  structureTemplate: StructureTemplate | null;
};

type ThingOption = {
  thing: Thing;
  label: string;
};
