import { autoinject, computedFrom } from 'aurelia-framework';

import { environment } from '../../environment';
import { AuthenticationService } from '../../services/AuthenticationService';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { Dialogs } from '../../classes/Dialogs';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { BiometricAuthenticationService } from '../../services/BiometricAuthenticationService/BiometricAuthenticationService';
import { I18N } from 'aurelia-i18n';

@autoinject()
export class Authentication {
  protected email = '';
  protected password = '';
  protected username = '';
  protected confirmationPassword = '';

  protected acceptTerms = false;
  protected acceptPrivacyTerms = false;

  protected showPassword = false;

  protected Mode = Mode;

  protected mode: Mode = Mode.LOGIN;

  protected loading = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly biometricAuthService: BiometricAuthenticationService,
    private readonly i18n: I18N
  ) {
    this.setMode(Mode.LOGIN);
    this.suggestBiometricAuthentication();
  }

  protected attached(): void {
    if (navigator.userAgent.indexOf('Trident') > -1) {
      Dialogs.browserCheckDialog();
    }
  }

  protected async handleLoginFormSubmit(): Promise<void> {
    if (this.email.trim() === '' || this.password.trim() === '') {
      NotificationHelper.notifyDanger('Alle Felder müssen ausgefüllt sein!');
    } else {
      this.loading = true;
      try {
        await this.authenticationService.login(this.email, this.password);
      } catch (error) {
        this.loading = false;
      }

      void this.showBiometricAuthenticationHint();
    }
  }

  protected handleToggleShowPasswordClick(): void {
    this.showPassword = !this.showPassword;
  }

  protected async handleResetPasswortFormSubmit(): Promise<void> {
    if (this.email === '') {
      NotificationHelper.notifyDanger('Alle Felder müssen ausgefüllt sein!');
    } else {
      this.loading = true;
      try {
        await this.authenticationService.sendPasswordResetEmail(this.email);
        this.loading = false;
        this.setMode(Mode.LOGIN);
      } catch (error) {
        this.loading = false;
      }
    }
  }

  protected async handleRegisterFormSubmit(): Promise<void> {
    if (
      this.email.trim() === '' ||
      this.username.trim() === '' ||
      this.password.trim() === ''
    ) {
      NotificationHelper.notifyDanger('Alle Felder müssen ausgefüllt sein!');
      return;
    }

    if (this.password !== this.confirmationPassword) {
      NotificationHelper.notifyDanger(
        'Beide Passwörter müssen übereinstimmen!'
      );
      return;
    }

    // TODO: use Utils.validatePassword ?
    this.loading = true;
    try {
      await this.authenticationService.register(
        this.username,
        this.email,
        this.password,
        this.confirmationPassword
      );
      this.loading = false;
      this.setMode(Mode.LOGIN);
    } catch (error) {
      this.loading = false;
    }
  }

  protected setMode(mode: Mode): void {
    this.mode = mode;
    this.email = '';
    this.password = '';
    this.username = '';
    this.confirmationPassword = '';
    this.showPassword = false;
    this.loading = false;
    this.acceptTerms = false;
    this.acceptPrivacyTerms = false;
  }

  @computedFrom()
  protected get isApp(): boolean {
    return DeviceInfoHelper.isApp();
  }

  @computedFrom()
  protected get signUpEnabled(): boolean {
    return !environment.disableSignUp;
  }

  protected async identifyAndLogin(): Promise<void> {
    const result = await this.biometricAuthService.verifyIdentity(
      this.i18n.tr('aureliaComponents.saveBiometricCredentialsPanel.confirm'),
      this.i18n.tr('aureliaComponents.saveBiometricCredentialsPanel.reason')
    );

    if (result.success) void this.loginWithSavedCredentials();
  }

  private suggestBiometricAuthentication(): void {
    if (
      this.biometricAuthService.getBiometricAuthenticationAvailable() &&
      this.biometricAuthService.getUserCredentialsSavedOnDevice()
    ) {
      this.mode = Mode.BIOMETRIC_LOGIN;
    }
  }

  private async showBiometricAuthenticationHint(): Promise<void> {
    if (
      this.biometricAuthService.getBiometricAuthenticationAvailable() &&
      this.biometricAuthService.getShowBiometricAuthenticationHintOnLogin()
    ) {
      const result =
        await this.biometricAuthService.showbiometricAuthenticationHintDialog();

      if (result.confirmed) void this.identifyAndSaveCredentials();
    }
  }

  private async identifyAndSaveCredentials(): Promise<void> {
    const result = await this.biometricAuthService.verifyIdentity(
      this.i18n.tr('aureliaComponents.saveBiometricCredentialsPanel.confirm'),
      this.i18n.tr('aureliaComponents.saveBiometricCredentialsPanel.reason')
    );

    if (result.success) void this.saveCredentials();
  }

  private async saveCredentials(): Promise<void> {
    await this.biometricAuthService.setCredentials(this.email, this.password);

    this.biometricAuthService.setShowBiometricAuthenticationHintOnLogin(false);
  }

  private async loginWithSavedCredentials(): Promise<void> {
    const credentials = await this.biometricAuthService.retrieveCredentials();
    if (credentials == null) {
      NotificationHelper.notifyDanger(
        'Login failed. Credentials could not be found.'
      );
      return;
    }

    await this.authenticationService.login(
      credentials.username,
      credentials.password
    );
  }
}

enum Mode {
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'resetPassword',
  BIOMETRIC_LOGIN = 'biometricLogin'
}
