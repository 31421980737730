export class MapUtils {
  private constructor() {}

  public static mapsAreEqual<TKey, TValue>({
    a,
    b,
    valuesAreEqual
  }: {
    a: Map<TKey, TValue>;
    b: Map<TKey, TValue>;
    valuesAreEqual: (options: { a: TValue; b: TValue }) => boolean;
  }): boolean {
    if (a.size !== b.size) {
      return false;
    }

    for (const key of a.keys()) {
      // both maps have the same size, but apparently they have different keys
      if (!b.has(key)) {
        return false;
      }

      const aValue = a.get(key) as TValue;
      const bValue = b.get(key) as TValue;

      if (!valuesAreEqual({ a: aValue, b: bValue })) {
        return false;
      }
    }

    return true;
  }
}
