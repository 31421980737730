import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { Defect } from '../../../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { defectEntityInfo } from '../../../../classes/EntityManager/entities/Defect/defectEntityInfo';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';

export class DefectAdapter implements EntityAdapter<Defect> {
  private editableUserGroupIds: Set<string> = new Set();

  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly currentUserService: CurrentUserService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private currentUser: User | null = null;

  constructor(options: DefectAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canEditField(defect: Defect): boolean {
    return this.hasRolePermissionsForUpdate(defect);
  }

  public canDeleteEntity(defect: Defect): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(defect.ownerUserGroupId)
      .getCanDeleteDefects();
  }

  public canEditDefect(defect: Defect): boolean {
    return this.hasRolePermissionsForUpdate(defect);
  }

  public canEditPictures(defect: Defect): boolean {
    return this.hasRolePermissionsForUpdate(defect);
  }

  public canEditProperties(defect: Defect): boolean {
    return this.hasRolePermissionsForUpdate(defect);
  }

  public canAddComments(defect: Defect): boolean {
    return (
      this.hasRolePermissionsForUpdate(defect) || this.defectIsAssigned(defect)
    );
  }

  public canSetDefectStatusToNone(defect: Defect): boolean {
    return (
      this.hasRolePermissionsForUpdate(defect) || this.defectIsAssigned(defect)
    );
  }

  public canSetDefectStatusToOpen(defect: Defect): boolean {
    return this.hasRolePermissionsForUpdate(defect);
  }

  public canSetDefectStatusToProcessed(defect: Defect): boolean {
    return (
      this.hasRolePermissionsForUpdate(defect) || this.defectIsAssigned(defect)
    );
  }

  public canSetDefectStatusToDone(defect: Defect): boolean {
    return this.hasRolePermissionsForUpdate(defect);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Defect,
    Defect
  > {
    return defectEntityInfo;
  }

  private hasRolePermissionsForUpdate(defect: Defect): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(defect.ownerUserGroupId)
      .getCanUpdateDefects();
  }

  private defectIsAssigned(defect: Defect): boolean {
    return this.currentUser?.id === defect.assigneeId;
  }
}

type DefectAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  currentUserService: CurrentUserService;
};
