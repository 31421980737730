import { computedFrom } from 'aurelia-framework';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAdapter } from '../EntityAdapter/ProcessTaskAdapter/ProcessTaskAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessTaskPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessTaskAdapter>,
  ProcessTask
> {
  @computedFrom('combinedRevision')
  public get canUploadPictures(): boolean {
    return this.checkAdapterSpecificPermissionByName('canUploadPictures');
  }

  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditField');
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskComments(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskComments'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskChecklistEntries(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskChecklistEntries'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskAppointments(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskAppointments'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskRecurringAppointments(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskRecurringAppointments'
    );
  }

  @computedFrom(
    'canEditField.processConfigurationActionStatusId',
    'canEditField.customActionStatusAbbreviation',
    'canEditField.customActionStatusName'
  )
  public get canEditActionStatus(): boolean {
    return (
      this.canEditField.processConfigurationActionStatusId &&
      this.canEditField.customActionStatusAbbreviation &&
      this.canEditField.customActionStatusName
    );
  }
}
