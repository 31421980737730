import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskGroupSubEntityUtils } from '../../BaseEntities/ProcessTaskGroupSubEntityUtils';
import { ProcessTaskGroupAuthorizationDto } from './ProcessTaskGroupAuthorizationDto';

export function createProcessTaskGroupAuthorizationEntityInfo<
  TEntity extends ProcessTaskGroupAuthorizationDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskGroupAuthorizationDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskGroupAuthorization,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskGroupAuthorization,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskGroupAuthorizationDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskGroupSubEntityUtils.createBaseFieldInfos(),
        userId: {
          name: 'userId',
          cascadeDeleteLocally: true,
          ref: EntityName.User
        },
        processConfigurationAuthorizationTypeId: {
          name: 'processConfigurationAuthorizationTypeId',
          ref: EntityName.ProcessConfigurationAuthorizationType
        }
      },
      options.additionalFieldInfos
    )
  };
}
