import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskChecklistEntryEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskChecklistEntry/processTaskChecklistEntryEntityInfo';
import { ProcessTaskChecklistEntry } from '../../../../classes/EntityManager/entities/ProcessTaskChecklistEntry/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskChecklistEntryAdapter
  implements EntityAdapter<ProcessTaskChecklistEntry>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: ProcessTaskChecklistEntryAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(
    processTaskChecklistEntry: ProcessTaskChecklistEntry
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processTaskChecklistEntry.ownerUserGroupId)
      .getCanDeleteProcessTaskChecklistEntries();
  }

  public canEditField(
    processTaskChecklistEntry: ProcessTaskChecklistEntry
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processTaskChecklistEntry.ownerUserGroupId)
      .getCanUpdateProcessTaskChecklistEntries();
  }

  public canEditGeneralFiles(
    processTaskChecklistEntry: ProcessTaskChecklistEntry
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processTaskChecklistEntry.ownerUserGroupId)
      .getCanUpdateProcessTaskChecklistEntries();
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskChecklistEntry,
    ProcessTaskChecklistEntry
  > {
    return processTaskChecklistEntryEntityInfo;
  }
}

export type ProcessTaskChecklistEntryAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
