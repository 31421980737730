import { autoinject, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

import { SocketService } from '../../services/SocketService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { RecordItModuleHelper } from '../../classes/RecordItModuleHelper';

import { PermissionHelper } from '../../classes/PermissionHelper';
import { GlobalSiteStateInfoService } from '../../services/GlobalSiteStateInfoService';
import { TooltipContent } from '../tooltip-content/tooltip-content';
import { GlobalFilter } from '../global-filter/global-filter';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PictureFileAutoDownloadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileAutoDownloadService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { PictureFileAutoUploadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileAutoUploadService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

/**
 * the header is just meant to be a global singleton
 * multiple instances would break some of the functionality
 *
 * @event open-synchronization-sidebar - fired when the synchronization sidebar should be opened
 * @event open-navigation-sidebar - fired when the navigation sidebar should be opened
 *
 * @cssvar --record-it-header-height - height of the header in px
 *
 * @aggregatorevent record-it-header:bread-crumb-is-visible-changed
 */
@autoinject()
export class RecordItHeader {
  private static instance: RecordItHeader | null = null;

  private isConnected: boolean = false;
  private isActualizing: boolean = false;
  @observable private isMobile: boolean;
  private globalFilterEnabled: boolean = false;
  private breadCrumbVisible: boolean = false;
  private currentUser: User | null = null;
  private availableModules: Array<string> = [];
  private globalSiteStateAnyWarning: boolean = false;

  private domElement: HTMLElement;
  private accountTooltipContent: TooltipContent | null = null;
  private globalFilterOverlay: HTMLElement | null = null;
  private globalFilterOverlayAnimator: ShowHideAnimator | null = null;

  private globalFilterViewModel: GlobalFilter | null = null;

  private subscriptionManager: SubscriptionManager;

  private itemsToSynchronizeCount: number = 0;
  private failedItemsToSynchronizeCount: number = 0;

  private pictureFilesToUpload: Array<PictureFile> = [];
  private pictureFilesToDownload: Array<PictureFile> = [];

  constructor(
    element: Element,
    private router: Router,
    private eventAggregator: EventAggregator,
    private readonly entityManager: AppEntityManager,
    private socketService: SocketService,
    private globalSiteStateInfoService: GlobalSiteStateInfoService,
    private readonly currentUserService: CurrentUserService,
    private readonly pictureFileAutoDownloadService: PictureFileAutoDownloadService,
    private readonly pictureFileAutoUploadService: PictureFileAutoUploadService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();

    this.isMobile = false;
  }

  protected bind(): void {
    if (!this.globalFilterOverlay) {
      throw new Error('globalFilterOverlay is not bound');
    }

    this.globalFilterOverlayAnimator = new ShowHideAnimator(
      this.globalFilterOverlay
    );
  }

  protected attached(): void {
    RecordItHeader.instance = this;

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.bindIsActualizing(
        (isActualizing) => {
          this.isActualizing = isActualizing;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.bindItemsToSynchronizeInfos(
        (itemsToSynchronizeInfos) => {
          this.itemsToSynchronizeCount = itemsToSynchronizeInfos.length;
          this.failedItemsToSynchronizeCount = itemsToSynchronizeInfos.filter(
            (i) => i.failed
          ).length;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.addDisposable(
      this.globalSiteStateInfoService.registerBinding(
        'anyWarning',
        (anyWarning) => {
          this.globalSiteStateAnyWarning = anyWarning;
        }
      )
    );

    this.subscriptionManager.subscribeToEvent(
      'router:navigation:complete',
      () => {
        this.updateBreadCrumbVisible();
        this.updateHeaderHeight();
      }
    );
    this.subscriptionManager.subscribeToResize(
      this.updateHeaderHeight.bind(this)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureFile,
      this.updatePictureFiles.bind(this)
    );
    this.updatePictureFiles();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    RecordItHeader.instance = null;
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateAvailableModules();
  }

  private updatePictureFiles(): void {
    this.pictureFilesToUpload =
      this.pictureFileAutoUploadService.getPictureFilesToUpload();
    this.pictureFilesToDownload =
      this.pictureFileAutoDownloadService.getPictureFilesToDownload();
  }

  private handleOpenSynchronizationSidebarClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'open-synchronization-sidebar',
      detail: null
    });
  }

  private handleMenuIconClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'open-navigation-sidebar',
      detail: null
    });
  }

  @computedFrom(
    'numberOfSynchronizationItems',
    'isActualizing',
    'synchronizationStatusOk'
  )
  private get synchronizationStatusClass(): string {
    if (
      this.isActualizing ||
      (this.numberOfSynchronizationItems > 0 && this.synchronizationStatusOk)
    ) {
      return ' fa-spin';
    }
    return '';
  }

  @computedFrom(
    'isConnected',
    'itemsToSynchronizeCount',
    'failedItemsToSynchronizeCount'
  )
  private get synchronizationStatusOk(): boolean {
    return !(
      (!this.isConnected && this.itemsToSynchronizeCount > 0) ||
      this.failedItemsToSynchronizeCount > 0
    );
  }

  @computedFrom('itemsToSynchronizeCount', 'pictureFilesToUpload.length')
  private get numberOfSynchronizationItems(): number {
    return this.itemsToSynchronizeCount + this.pictureFilesToUpload.length;
  }

  private handleSearchIconClick(): void {
    if (this.globalFilterOverlayAnimator && this.globalFilterViewModel) {
      this.globalFilterOverlayAnimator.moveInTop();
      this.globalFilterViewModel.focus();
    }
  }

  private handleGlobalFilterCleared(): void {
    if (this.globalFilterOverlayAnimator) {
      this.globalFilterOverlayAnimator.moveOutTop();
    }
  }

  private updateAvailableModules(): void {
    this.availableModules = this.currentUser
      ? PermissionHelper.getAvailableModulesForUser(this.currentUser)
      : [];
    this.updateBreadCrumbVisible();
  }

  private updateBreadCrumbVisible(): void {
    const oldVisible = this.breadCrumbVisible;

    const config = this.router.currentInstruction
      ? this.router.currentInstruction.config
      : null;
    const settings = config ? config.settings : null;
    if (!settings || settings.headerStyle === 'no-breadcrumb') {
      this.breadCrumbVisible = false;
    } else if (settings.headerStyle === 'breadcrumb') {
      const overviewPage =
        RecordItModuleHelper.getOverviewPageRouteForModuleName(
          settings.recordItModuleName
        );
      // do not show the breadcrumb on module overviewPages, when the user has access only to this module (it's basically the new home page)
      this.breadCrumbVisible =
        (config && config.route !== overviewPage) ||
        this.availableModules.length !== 1;
    }

    if (this.breadCrumbVisible !== oldVisible) {
      this.eventAggregator.publish(
        'record-it-header:bread-crumb-is-visible-changed'
      );
    }
  }

  private updateHeaderHeight(): void {
    document.documentElement.style.setProperty(
      '--record-it-header-height',
      this.calculateHeaderHeight() + 'px'
    );
  }

  private calculateHeaderHeight(): number {
    return parseInt(window.getComputedStyle(this.domElement).height || '0px');
  }

  protected isMobileChanged(): void {
    if (!this.globalFilterOverlayAnimator) {
      return;
    }

    if (!this.isMobile) {
      this.globalFilterOverlayAnimator.moveOutTop();
    } else if (this.globalFilterViewModel && this.globalFilterViewModel.value) {
      this.globalFilterOverlayAnimator.moveInTop();
    }
  }

  /**
   * returns null if there is currently no header attached
   */
  public static getHeaderHeight(): number | null {
    return this.instance ? this.instance.calculateHeaderHeight() : null;
  }

  /**
   * you probably should also listen to the the `record-it-header:bread-crumb-is-visible-changed` event
   */
  public static breadCrumbIsVisible(): boolean {
    return this.instance ? this.instance.breadCrumbVisible : false;
  }
}
