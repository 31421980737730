import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { GeneralFileHelper as CommonGeneralFileHelper } from 'common/EntityHelper/GeneralFileHelper';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { ProcessTaskGroupUtils } from '../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupUtils';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditThingGroupDialog } from '../../dialogs/edit-thing-group-dialog/edit-thing-group-dialog';
import { EditPersonDialog } from '../../personComponents/edit-person-dialog/edit-person-dialog';
import { Utils } from '../../classes/Utils/Utils';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { MultiThingSelectAndEditWidget } from '../../inputComponents/multi-thing-select-and-edit-widget/multi-thing-select-and-edit-widget';
import {
  MultiFileInput,
  TSelectedFile
} from '../../inputComponents/multi-file-input/multi-file-input';
import { ProcessConfigurationProcessTaskPropertiesConfiguration } from '../../../../common/src/Types/ProcessConfigurationProcessTaskPropertiesConfiguration';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import {
  AppAnyEntityWithEntityName,
  EntityName
} from '../../classes/EntityManager/entities/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskComment } from '../../classes/EntityManager/entities/ProcessTaskComment/types';
import {
  ProcessTaskGroup,
  ProcessTaskGroupCreationEntity
} from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { GeneralFileUploadService } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessTaskCreationService } from '../../classes/EntityManager/entities/ProcessTask/ProcessTaskCreationService';
import { ProcessTaskGroupContactPerson } from 'common/Types/Entities/ProcessTaskGroup/ProcessTaskGroupDto';
import { expression } from '../../hooks/dependencies';
import { computed } from '../../hooks/computed';

@autoinject()
export class create_process_task_group {
  public static TEMPORARY_GROUP_NAME = 'CreateProcessTaskGroup';
  private static TEMPORARY_GROUP_NAME_FOR_PERMANENT_ENTITIES =
    'CreateProcessTaskGroupPermanentEntities';

  private static TEMPORARY_GROUP_NAME_PROPERTIES =
    'CreateProcessTaskGroupProperties';

  private readonly subscriptionManager: SubscriptionManager;

  private multiThingSelectAndEditWidget: MultiThingSelectAndEditWidget | null =
    null;

  private processTaskGroupToCreate: ProcessTaskGroupToCreate | null = null;
  private selectedThingGroup: ThingGroup | null = null;
  private selectedThingIds: Array<string> = [];
  @observable private userGroupId: string | null;
  private configurationSelectionVisible: boolean = false;

  /**
   * initial process configuration step when creating the process tasks
   */
  private initialProcessConfigurationStepId: string | null = null;
  private firstProcessConfigurationStepId: string | null = null;

  private inputIsValid: boolean = false;

  private multiFileInput: MultiFileInput | null = null;

  private selectedOfferReceiverPerson: Person | null = null;

  private selectedInvoiceReceiverPerson: Person | null = null;

  private defaultPropertiesToCreate: Array<Property> = [];

  private actionStatusId: string | null = null;

  private customActionStatusName: string | null = null;

  private customActionStatusAbbreviation: string | null = null;

  private currentUser: User | null = null;

  private firstThingToPersonPerson: Person | null = null;

  private processTaskConfiguration: ProcessConfiguration | null = null;

  private _boundUpdateFirstThingToPersonPerson =
    this.updateFirstThingToPersonPerson.bind(this);

  private CommonGeneralFileHelper = CommonGeneralFileHelper;
  private PersonUtils = PersonUtils;
  private create_process_task_group = create_process_task_group;

  constructor(
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly generalFileUploadService: GeneralFileUploadService,
    private readonly currentUserService: CurrentUserService,
    private readonly i18n: I18N,
    private readonly processTaskCreationService: ProcessTaskCreationService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.userGroupId = null;
  }

  public activate(params: { user_group_id: string }): void {
    this.selectedThingIds = [];
    this.userGroupId = params.user_group_id;
    this.processTaskGroupToCreate = {
      ownerUserGroupId: params.user_group_id,
      processConfigurationId: null,
      thingGroupOwnerPersonId: null,
      contactPersons: [{ personId: null, purposeOfPerson: null }],
      referenceCode: null,
      thingGroupId: null,
      temporaryGroupName: create_process_task_group.TEMPORARY_GROUP_NAME,
      note: null,
      offerReceiverPersonId: null,
      invoiceReceiverPersonId: null,
      offerReceiverIsInvoiceReceiver: true
    };

    this.updateProcessConfigurations();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateProcessConfigurations.bind(this)
    );
    this.updateProcessConfigurations();

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerHooks({
        entityIdUpgraded: this.handleEntityIdUpgraded.bind(this)
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      this._boundUpdateFirstThingToPersonPerson
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged((currentUser) => {
        this.currentUser = currentUser;
      })
    );
    this.currentUser = this.currentUserService.getCurrentUser();

    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'selectedThingIds',
      this._boundUpdateFirstThingToPersonPerson
    );
  }

  protected detached(): void {
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      create_process_task_group.TEMPORARY_GROUP_NAME
    );
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      create_process_task_group.TEMPORARY_GROUP_NAME_PROPERTIES
    );
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      create_process_task_group.TEMPORARY_GROUP_NAME_FOR_PERMANENT_ENTITIES
    );
    this.subscriptionManager.disposeSubscriptions();
  }

  private userGroupIdChanged(): void {
    this.updateDefaultPropertiesToCreate();
  }

  @computed(expression('processTaskConfiguration.contactPersonPurposes'))
  protected get allowMultipleContactPersons(): boolean {
    return !!this.processTaskConfiguration?.contactPersonPurposes?.length;
  }

  private updateProcessConfigurations(): void {
    if (this.userGroupId && this.processTaskGroupToCreate) {
      this.processTaskConfiguration =
        this.entityManager.processConfigurationRepository.getByUserGroupId(
          this.userGroupId
        )[0] ?? null;
      if (this.processTaskConfiguration) {
        this.processTaskGroupToCreate.processConfigurationId =
          this.processTaskConfiguration.id;
        this.handleProcessConfigurationIdChanged();
        this.configurationSelectionVisible = false;
      } else {
        this.configurationSelectionVisible = true;
      }
    } else {
      this.configurationSelectionVisible = true;
    }
  }

  private handleThingGroupSelected(): void {
    this.updateThingGroupRelatedPersonIds();

    this.selectedThingIds = [];
    if (this.multiThingSelectAndEditWidget) {
      this.multiThingSelectAndEditWidget.ensureAtleastOneThing();
    }
    this.handleFormInputValueChanged();
  }

  private handleThingGroupChanged(propertyName: keyof ThingGroup): void {
    if (propertyName === 'ownerPersonId') {
      this.updateThingGroupRelatedPersonIds();
    }
  }

  private updateThingGroupRelatedPersonIds(): void {
    if (!this.processTaskGroupToCreate) return;

    if (this.selectedThingGroup) {
      this.processTaskGroupToCreate.thingGroupOwnerPersonId =
        this.selectedThingGroup.ownerPersonId;

      if (!this.processTaskGroupToCreate.contactPersons[0]) {
        return;
      }
      const contactPersonId =
        this.selectedThingGroup.contactPersonId ??
        this.selectedThingGroup.ownerPersonId;
      this.processTaskGroupToCreate.contactPersons[0].personId =
        contactPersonId;
    } else {
      this.processTaskGroupToCreate.thingGroupOwnerPersonId = null;
      if (this.processTaskGroupToCreate.contactPersons[0]) {
        this.processTaskGroupToCreate.contactPersons[0].personId = null;
      }
    }
  }

  private handleProcessConfigurationIdChanged(): void {
    let steps: Array<ProcessConfigurationStep> = [];
    if (
      this.processTaskGroupToCreate &&
      this.processTaskGroupToCreate.processConfigurationId
    ) {
      steps =
        this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
          this.processTaskGroupToCreate.processConfigurationId
        );
    }

    this.firstProcessConfigurationStepId = steps[0] ? steps[0].id : null;

    if (steps[1]) {
      this.initialProcessConfigurationStepId = steps[1].id;
    } else {
      this.initialProcessConfigurationStepId =
        this.firstProcessConfigurationStepId;
    }
    this.handleFormInputValueChanged();

    this.updateDefaultPropertiesToCreate();
  }

  private updateDefaultPropertiesToCreate(): void {
    this.defaultPropertiesToCreate =
      this.getDefaultProcessTaskGroupProperties();
  }

  private getDefaultProcessTaskGroupProperties(): Array<Property> {
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      create_process_task_group.TEMPORARY_GROUP_NAME_PROPERTIES
    );

    const config = this.processTaskConfiguration
      ?.processTaskPropertiesConfigurationJson
      ? (JSON.parse(
          this.processTaskConfiguration.processTaskPropertiesConfigurationJson
        ) as ProcessConfigurationProcessTaskPropertiesConfiguration)
      : null;

    const propertyConfigs = config
      ? config.defaultProperties.filter((prop) => prop.showOnCreation)
      : [];

    const userGroupId = this.userGroupId;
    if (!userGroupId) return [];

    return propertyConfigs.map((propertyConfig) => {
      return this.entityManager.propertyRepository.create({
        value: '',
        ...propertyConfig,
        ownerUserGroupId: userGroupId,
        ownerProcessTaskGroupId: 'processTaskGroupYetToCreate',
        ownerProcessTaskId: 'processTaskYetToCreate',
        processTaskId: 'processTaskYetToCreate',
        temporaryGroupName:
          create_process_task_group.TEMPORARY_GROUP_NAME_PROPERTIES,
        shadowEntity: true
      });
    });
  }

  private handleFormInputValueChanged(): void {
    this.validatePageInput();
  }

  private validatePageInput(): void {
    let isValid = false;
    if (
      this.processTaskGroupToCreate &&
      this.processTaskGroupToCreate.processConfigurationId &&
      this.initialProcessConfigurationStepId &&
      this.selectedThingGroup &&
      this.selectedThingIds.length >= 1
    ) {
      isValid = true;
    }
    this.inputIsValid = isValid;
  }

  private async handleCreateProcessTaskGroupClick(): Promise<void> {
    if (
      !this.initialProcessConfigurationStepId ||
      !this.inputIsValid ||
      !this.firstProcessConfigurationStepId
    ) {
      return;
    }

    assertNotNullOrUndefined(
      this.multiFileInput,
      "can't create_process_task_group.handleCreateProcessTaskGroupClick without a multiFileInput"
    );

    this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
      create_process_task_group.TEMPORARY_GROUP_NAME_FOR_PERMANENT_ENTITIES
    );

    // we can cast the _processTaskGroupToCreate since it has to have valid (non null) data because we validated it
    const createdProcessTaskGroup =
      this.entityManager.processTaskGroupRepository.create(
        this.processTaskGroupToCreate as ProcessTaskGroupCreationEntity
      );

    const initialProcessConfigurationStepId =
      this.initialProcessConfigurationStepId;
    const firstProcessConfigurationStepId =
      this.firstProcessConfigurationStepId;
    const hasActionStatus =
      !!this.actionStatusId || !!this.customActionStatusName;

    for (const thingId of this.selectedThingIds) {
      const processTask = this.processTaskCreationService.createProcessTask({
        thingId: thingId,
        currentProcessConfigurationStepId: hasActionStatus
          ? firstProcessConfigurationStepId
          : initialProcessConfigurationStepId,
        ownerProcessTaskGroupId: createdProcessTaskGroup.id,
        ownerUserGroupId: createdProcessTaskGroup.ownerUserGroupId,
        processConfigurationActionStatusId: this.actionStatusId,
        customActionStatusName: this.customActionStatusName,
        customActionStatusAbbreviation: this.customActionStatusAbbreviation,
        temporaryGroupName: create_process_task_group.TEMPORARY_GROUP_NAME
      });

      await this.multiFileInput.selectedFilesLoadedAwaitable();
      if (this.multiFileInput.selectedFiles.length > 0) {
        const comment = this.createNewComment(
          createdProcessTaskGroup,
          processTask
        );
        this._createCommentFiles(comment, this.multiFileInput.selectedFiles);
      }

      this.createDefaultProperties(processTask);

      await this.processTaskLoggingService.logProcessTaskCreated(processTask);
    }

    ProcessTaskGroupUtils.navigateToEditProcessTaskGroupsPage(this.router);
  }

  public createNewComment(
    processTaskGroup: ProcessTaskGroup,
    processTask: ProcessTask
  ): ProcessTaskComment {
    if (!this.currentUser)
      throw new Error("can't create a comment without a current user");

    const currentStepId =
      this.firstProcessConfigurationStepId ||
      processTask.currentProcessConfigurationStepId;

    return this.entityManager.processTaskCommentRepository.create({
      ownerProcessTaskGroupId: processTaskGroup.id,
      ownerUserGroupId: processTaskGroup.ownerUserGroupId,
      ownerProcessTaskId: processTask.id,
      createdAtProcessConfigurationStepId: currentStepId,
      date: new Date().toISOString(),
      userId: this.currentUser.id
    });
  }

  private _createCommentFiles(
    comment: ProcessTaskComment,
    files: Array<TSelectedFile>
  ): void {
    files.forEach((file) => {
      if (!file.dataUrl) {
        // should never happen since we wait for all files to be loaded before calling this function
        console.error('selected file has no dataUrl');
        return;
      }

      const { name, extension } = Utils.getFilePathComponents(file.name ?? '');

      const generalFile = this.entityManager.generalFileRepository.create({
        name: name,
        processTaskCommentId: comment.id,
        ownerProcessTaskGroupId: comment.ownerProcessTaskGroupId,
        ownerProcessTaskId: comment.ownerProcessTaskId,
        ownerUserGroupId: comment.ownerUserGroupId,
        temporaryGroupName: comment.temporaryGroupName
      });

      this.generalFileUploadService.uploadGeneralFile(
        generalFile,
        file.dataUrl,
        extension || ''
      );
    });
  }

  private createDefaultProperties(processTask: ProcessTask): void {
    const properties = this.entityManager.propertyRepository.getByProcessTaskId(
      processTask.id
    );

    for (const defaultProperty of this.defaultPropertiesToCreate) {
      const property = properties.find((p) =>
        PropertyHelper.isTheSameProperty(defaultProperty, p)
      );
      if (property) {
        Object.assign(
          property,
          PropertyHelper.extractValueDataFromProperty(defaultProperty)
        );
        this.entityManager.propertyRepository.update(property);
      }
    }
  }

  private handleCancelClick(): void {
    ProcessTaskGroupUtils.navigateToEditProcessTaskGroupsPage(this.router);
  }

  private handleEntityIdUpgraded(
    entityWithEntityName: AppAnyEntityWithEntityName
  ): void {
    switch (entityWithEntityName.entityName) {
      case EntityName.ThingGroup:
        this.updatedThingGroupId();
        break;
      case EntityName.Thing:
        this.updatedThingId();
        break;
      case EntityName.UserGroup:
        this.updatedUserGroupId();
        break;
      case EntityName.ProcessConfiguration:
        this.updatedProcessConfigurationId();
        break;
      case EntityName.Person:
        this.updatedPersonId();
        break;
      default:
        break;
    }
  }

  private updatedThingGroupId(): void {
    if (
      this.processTaskGroupToCreate &&
      this.processTaskGroupToCreate.thingGroupId
    ) {
      const thingGroup =
        this.entityManager.thingGroupRepository.getByOriginalId(
          this.processTaskGroupToCreate.thingGroupId
        );
      if (thingGroup) {
        this.processTaskGroupToCreate.thingGroupId = thingGroup.id;
      }
    }
  }

  private updatedThingId(): void {
    this.selectedThingIds.forEach((id, index) => {
      const thing = this.entityManager.thingRepository.getByOriginalId(id);
      if (thing) {
        this.selectedThingIds[index] = thing.id;
      }
    });
  }

  private updatedUserGroupId(): void {
    if (this.processTaskGroupToCreate) {
      const userGroup = this.entityManager.userGroupRepository.getByOriginalId(
        this.processTaskGroupToCreate.ownerUserGroupId
      );
      if (userGroup) {
        this.processTaskGroupToCreate.ownerUserGroupId = userGroup.id;
        this.userGroupId = userGroup.id;
      }
    }
  }

  private updatedProcessConfigurationId(): void {
    if (
      this.processTaskGroupToCreate &&
      this.processTaskGroupToCreate.processConfigurationId
    ) {
      const processConfiguration =
        this.entityManager.processConfigurationRepository.getByOriginalId(
          this.processTaskGroupToCreate.processConfigurationId
        );
      if (processConfiguration) {
        this.processTaskGroupToCreate.processConfigurationId =
          processConfiguration.id;
      }
    }
  }

  private updatedPersonId(): void {
    if (
      this.processTaskGroupToCreate &&
      this.processTaskGroupToCreate.contactPersons[0]?.personId
    ) {
      const person = this.entityManager.personRepository.getByIdOrOriginalId(
        this.processTaskGroupToCreate.contactPersons[0].personId
      );
      if (person) {
        this.processTaskGroupToCreate.contactPersons[0].personId = person.id;
      }
    }
  }

  private handleThingGroupWidgetEditIconClicked(): void {
    if (!this.selectedThingGroup) return;
    void EditThingGroupDialog.open({
      thingGroup: this.selectedThingGroup
    });
  }

  private handleOfferReceiverWidgetEditIconClicked(): void {
    if (!this.selectedOfferReceiverPerson) return;
    void EditPersonDialog.open({
      person: this.selectedOfferReceiverPerson
    });
  }

  private handleOfferReceiverWidgetCopyIconClicked(
    selectedOfferReceiverPerson: Person
  ): void {
    assertNotNullOrUndefined(
      this.processTaskGroupToCreate,
      "can't create_process_task_group.handleOfferReceiverWidgetCopyIconClicked without processTaskGroupToCreate"
    );

    const person = this.entityManager.personRepository.create({
      ...selectedOfferReceiverPerson,
      lastName:
        selectedOfferReceiverPerson.lastName +
        this.i18n.tr('generalPages.createProcessTaskGroup.copiedPersonSuffix')
    });

    this.processTaskGroupToCreate.offerReceiverPersonId = person.id;
  }

  private handleInvoiceReceiverWidgetEditIconClicked(): void {
    const invoiceReceiverPerson =
      this.processTaskGroupToCreate &&
      this.processTaskGroupToCreate.offerReceiverIsInvoiceReceiver
        ? this.selectedOfferReceiverPerson
        : this.selectedInvoiceReceiverPerson;
    if (!invoiceReceiverPerson) return;

    void EditPersonDialog.open({
      person: invoiceReceiverPerson
    });
  }

  private handleInvoiceReceiverWidgetCopyIconClicked(
    selectedInvoiceReceiverPerson: Person
  ): void {
    assertNotNullOrUndefined(
      this.processTaskGroupToCreate,
      "can't create_process_task_group.handleOfferReceiverWidgetCopyIconClicked without processTaskGroupToCreate"
    );

    const person = this.entityManager.personRepository.create({
      ...selectedInvoiceReceiverPerson,
      lastName:
        selectedInvoiceReceiverPerson.lastName +
        this.i18n.tr('generalPages.createProcessTaskGroup.copiedPersonSuffix')
    });

    this.processTaskGroupToCreate.invoiceReceiverPersonId = person.id;
  }

  private updateFirstThingToPersonPerson(): void {
    const firstThingId = this.selectedThingIds.length
      ? this.selectedThingIds[0]
      : null;
    const thingToPerson = firstThingId
      ? this.entityManager.thingToPersonRepository.getByThingId(firstThingId)[0]
      : null;
    const firstThingToPersonId = thingToPerson ? thingToPerson.personId : null;

    if (firstThingToPersonId) {
      this.firstThingToPersonPerson =
        this.entityManager.personRepository.getById(firstThingToPersonId) ||
        null;
    } else {
      this.firstThingToPersonPerson = null;
    }
  }

  private handleCopyContactPersonFromFirstThing(): void {
    if (!this.processTaskGroupToCreate || !this.firstThingToPersonPerson)
      return;
    this.processTaskGroupToCreate.offerReceiverPersonId =
      this.firstThingToPersonPerson.id;
  }
}

export type ProcessTaskGroupToCreate = {
  processConfigurationId: string | null;
  ownerUserGroupId: string;
  thingGroupId: string | null;
  thingGroupOwnerPersonId: string | null;
  contactPersons: Array<ProcessTaskGroupContactPerson<string>>;
  referenceCode: string | null;
  note: string | null;
  temporaryGroupName: string;
  offerReceiverPersonId: string | null;
  invoiceReceiverPersonId: string | null;
  offerReceiverIsInvoiceReceiver: boolean;
};
