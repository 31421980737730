import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { reportEntityInfo } from '../../../../classes/EntityManager/entities/Report/reportEntityInfo';
import { Report } from '../../../../classes/EntityManager/entities/Report/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import type { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ReportAdapter implements EntityAdapter<Report> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;

  private editableUserGroupIds: Set<string> = new Set();

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: ReportAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );
    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );
    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(report: Report): boolean {
    return this.entityIsEditableUserGroup(report);
  }

  public canEditField(report: Report): boolean {
    return this.entityIsEditableUserGroup(report);
  }

  public canSignReport(report: Report): boolean {
    if (!this.roleBasedPermissions) return false;
    return this.roleBasedPermissions
      .inUserGroupId(report.ownerUserGroupId)
      .getCanCreateReportSignatures();
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Report,
    Report
  > {
    return reportEntityInfo;
  }

  private entityIsEditableUserGroup(report: Report): boolean {
    return this.editableUserGroupIds.has(report.ownerUserGroupId);
  }
}

export type ReportAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
};
