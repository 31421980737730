import { EntityGroup } from './EntityGrouper';

export class EntityGroupUtils {
  private constructor() {}

  /**
   * the returned entities will be ordered by the groups
   */
  public static getAllEntities<T>(groups: Array<EntityGroup<T>>): Array<T> {
    const entities: Array<T> = [];

    for (const group of groups) {
      entities.push(...group.entities);
    }

    return entities;
  }

  /**
   * Calls the filterCallback for each entity.
   * Returns only groups with atleast 1 remaining entity.
   */
  public static filterEntities<T>(
    groups: Array<EntityGroup<T>>,
    filterCallback: (entity: T) => boolean
  ): Array<EntityGroup<T>> {
    const filteredGroups: Array<EntityGroup<T>> = [];

    for (const group of groups) {
      const entities = group.entities.filter(filterCallback);

      if (entities.length) {
        filteredGroups.push({
          ...group,
          entities
        });
      }
    }

    return filteredGroups;
  }
}
