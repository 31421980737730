import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskGroupEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskGroup/processTaskGroupEntityInfo';
import { ProcessTaskGroup } from '../../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskGroupAdapter
  implements EntityAdapter<ProcessTaskGroup>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskGroupIdsWhereUserIsAuthorized = new Set<string>();

  constructor(options: ProcessTaskGroupAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskGroupIdsWhereUserIsAuthorized }) => {
          this.processTaskGroupIdsWhereUserIsAuthorized =
            processTaskGroupIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(processTaskGroup: ProcessTaskGroup): boolean {
    return this.checkPermission({
      processTaskGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskGroups()
    });
  }

  public canEditField(processTaskGroup: ProcessTaskGroup): boolean {
    return this.checkPermission({
      processTaskGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskGroups()
    });
  }

  public canCreateProcessTaskGroupAuthorizations(
    processTaskGroup: ProcessTaskGroup
  ): boolean {
    return this.checkPermission({
      processTaskGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskGroupAuthorizations()
    });
  }

  public canSeeProcessTaskGroupAuthorizations(
    processTaskGroup: ProcessTaskGroup
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processTaskGroup.ownerUserGroupId)
      .getControlEntityVisibilityWithAuthorizations();
  }

  public canCreateProcessTasks(processTaskGroup: ProcessTaskGroup): boolean {
    return this.checkPermission({
      processTaskGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTasks()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskGroup,
    ProcessTaskGroup
  > {
    return processTaskGroupEntityInfo;
  }

  private checkPermission({
    processTaskGroup,
    checkRolePermission
  }: {
    processTaskGroup: ProcessTaskGroup;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !this.roleBasedPermissions ||
      !this.processTaskGroupIdsWhereUserIsAuthorized.has(processTaskGroup.id)
    ) {
      return false;
    }

    return checkRolePermission(
      this.roleBasedPermissions.inUserGroupId(processTaskGroup.ownerUserGroupId)
    );
  }
}

export type ProcessTaskGroupAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
