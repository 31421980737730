import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskRecurringAppointmentEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/processTaskRecurringAppointmentEntityInfo';
import { ProcessTaskRecurringAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskRecurringAppointmentAdapter
  implements EntityAdapter<ProcessTaskRecurringAppointment>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: ProcessTaskRecurringAppointmentAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public canDeleteEntity(
    appointment: ProcessTaskRecurringAppointment
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanDeleteProcessTaskRecurringAppointments();
  }

  public canEditField(appointment: ProcessTaskRecurringAppointment): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanUpdateProcessTaskRecurringAppointments();
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskRecurringAppointment,
    ProcessTaskRecurringAppointment
  > {
    return processTaskRecurringAppointmentEntityInfo;
  }
}

export type ProcessTaskRecurringAppointmentAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
