import { assertNotNullOrUndefined } from 'common/Asserts';

import { NotificationHelper } from './NotificationHelper';
import { DeviceInfoHelper } from './DeviceInfoHelper';
import { environment } from '../environment';

enum AndroidEnvironment {
  NORMAL = 'normal',
  STAGING = 'staging',
  LOCALHOST = 'localhost',
  EMULATOR = 'emulator'
}

const ServerUrl = {
  PROD: environment.prodServerUrl,
  STAG: environment.stagingServerUrl
};

const AndroidEnvironmentToUrlMap: Record<AndroidEnvironment, string> = {
  [AndroidEnvironment.NORMAL]: environment.appServerUrl ?? ServerUrl.PROD,
  [AndroidEnvironment.STAGING]: ServerUrl.STAG,
  [AndroidEnvironment.LOCALHOST]: 'http://localhost:3550',
  [AndroidEnvironment.EMULATOR]: 'http://10.0.2.2:3550'
};

export class UrlManager {
  public static localFolder = 'cdvfile:///localhost/persistent/'; // safe default
  // set this to ServerUrl.STAG to connect with the staging server
  public static webFolder: string = ServerUrl.PROD;
  public static downloadFolder: string | null = null;
  public static cacheFolder: string | null = null;

  /**
   * path where all the public/dist/ files are located at
   */
  public static baseClientFilePath = '';

  private static initialized = false;

  // these two definitions are here just for debugging purposes
  private static android: AndroidEnvironment = AndroidEnvironment.NORMAL;

  public static init(): void {
    if (this.initialized) {
      return;
    }

    if (DeviceInfoHelper.isApp()) {
      assertNotNullOrUndefined(window.cordova, 'cordova is not defined');

      this.cacheFolder = window.cordova.file.cacheDirectory;

      if (DeviceInfoHelper.isAndroidDevice()) {
        this.localFolder = window.cordova.file.externalDataDirectory
          ? window.cordova.file.externalDataDirectory
          : window.cordova.file.dataDirectory;
        this.downloadFolder = window.cordova.file.externalCacheDirectory
          ? window.cordova.file.externalCacheDirectory
          : window.cordova.file.cacheDirectory;

        this.webFolder = AndroidEnvironmentToUrlMap[UrlManager.android];
      } else if (DeviceInfoHelper.isIOSDevice()) {
        this.localFolder = window.cordova.file.dataDirectory;
        this.downloadFolder = window.cordova.file.documentsDirectory;

        this.webFolder = environment.appServerUrl ?? this.webFolder;
      }
    } else {
      this.webFolder = this.buildBaseUrl();
    }

    this.initialized = true;
    this.updateBaseClientPath();
  }

  public static showNotificationForCurrentEnvironment(): void {
    if (this.webFolder !== ServerUrl.PROD) {
      NotificationHelper.notifyNeutral('Server: ' + this.webFolder);
    }

    if (
      window.location.hostname === 'localhost' &&
      this.webFolder.search('localhost') < 0
    ) {
      console.warn('Remote database loaded');
    }
  }

  private static buildBaseUrl(): string {
    let baseUrl = window.location.hostname
      ? window.location.protocol + '//' + window.location.hostname
      : this.webFolder;

    const port = window.location.port ? ':' + window.location.port : '';
    baseUrl += port;

    return baseUrl;
  }

  private static updateBaseClientPath(): void {
    const basePath = window.location.origin + window.location.pathname;
    const index = basePath.indexOf('index.html');
    this.baseClientFilePath =
      index >= 0 ? basePath.substring(0, index) : basePath;
  }
}
