import { bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * @event {CustomRadioButtonCheckedChanged} checked-changed
 *
 * @attribute data-narrow - a narrower version of the button
 * @slot [label] - optional. Replaces the label binding with an custom element.
 */
export class CustomRadiobutton {
  /** @type {string} */
  @bindable label = '';

  /**
   * name of the radio button group
   * radio buttons with the same name will uncheck each other
   * @type {string|null}
   */
  @bindable name = null;

  /**
   * @type {any}
   */
  @bindable value = false;

  /**
   * @type {any}
   */
  @bindable model = true;

  /** @type {boolean} */
  @bindable disabled = false;

  /** @type {HTMLElement} */
  //@ts-ignore
  _domElement;

  _handleRadiobuttonChanged() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'checked-changed',
      bubbles: true,
      detail: {
        checked: this.value
      }
    });
  }
}

/**
 * @typedef {import('../../classes/DomEventHelper').NamedCustomEvent<'checked-changed', { checked: any }>} CustomRadioButtonCheckedChanged
 */
