import { autoinject } from 'aurelia-dependency-injection';

import {
  PictureFileExtension,
  PictureFileType
} from 'common/Types/Entities/PictureFile/PictureFileDto';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { DeviceInfoHelper } from '../../../DeviceInfoHelper';
import { ImageHelper } from '../../../ImageHelper';
import { FileUtils } from '../../../Utils/FileUtils/FileUtils';
import { AppEntityManager } from '../AppEntityManager';
import { Picture } from '../Picture/types';
import { PictureFilePathService } from './PictureFilePathService';
import { PictureFileUploadService } from './PictureFileUploadService';
import { PictureFile } from './types';
import { PictureFileNameUtils } from './PictureFileNameUtils';
import { UrlManager } from '../../../UrlManager';

@autoinject()
export class SavePictureFileDataUrlService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFileUploadService: PictureFileUploadService,
    private readonly pictureFilePathService: PictureFilePathService
  ) {}

  public saveOriginalPictureDataUrl(
    picture: Picture,
    dataUrl: string,
    showDialogs: boolean = true
  ): void {
    let originalPictureFile =
      this.entityManager.pictureFileRepository.getOriginalPictureFileByPictureId(
        picture.id
      );
    if (!originalPictureFile) {
      originalPictureFile =
        this.entityManager.pictureFileRepository.createPictureFileForPicture(
          picture,
          PictureFileType.ORIGINAL
        );
    }

    originalPictureFile.file_created = Date.now();
    originalPictureFile.file_uploaded = false;
    this.entityManager.pictureFileRepository.update(originalPictureFile);

    void this.saveDataUrl(originalPictureFile, dataUrl, showDialogs);
  }

  public saveCroppedPictureDataUrl(picture: Picture, dataUrl: string): void {
    let croppedPictureFile =
      this.entityManager.pictureFileRepository.getCroppedPictureFileByPictureId(
        picture.id
      );
    if (!croppedPictureFile) {
      croppedPictureFile =
        this.entityManager.pictureFileRepository.createPictureFileForPicture(
          picture,
          PictureFileType.CROPPED
        );
    }

    croppedPictureFile.file_created = Date.now() + 2; // just to make sure that this is newer than the edited picture (if all PictureFiles have a type in the database you can remove the + 2)
    croppedPictureFile.file_uploaded = false;
    this.entityManager.pictureFileRepository.update(croppedPictureFile);

    void this.saveDataUrl(croppedPictureFile, dataUrl);
  }

  public saveSketchPictureDataUrl(picture: Picture, dataUrl: string): void {
    let sketchPictureFile =
      this.entityManager.pictureFileRepository.getSketchPictureFileByPictureId(
        picture.id
      );
    if (!sketchPictureFile) {
      sketchPictureFile =
        this.entityManager.pictureFileRepository.createPictureFileForPicture(
          picture,
          PictureFileType.SKETCH,
          PictureFileExtension.SVG
        );
    }

    sketchPictureFile.file_created = Date.now() + 2; // just to make sure that this is newer than the edited picture (if all PictureFiles have a type in the database you can remove the + 2)
    sketchPictureFile.file_uploaded = false;
    this.entityManager.pictureFileRepository.update(sketchPictureFile);

    void this.saveDataUrl(sketchPictureFile, dataUrl);
  }

  public saveEditedPictureDataUrl(picture: Picture, dataUrl: string): void {
    let editedPictureFile =
      this.entityManager.pictureFileRepository.getEditedPictureFileByPictureId(
        picture.id
      );
    if (!editedPictureFile) {
      editedPictureFile =
        this.entityManager.pictureFileRepository.createPictureFileForPicture(
          picture,
          PictureFileType.EDITED
        );
    }

    editedPictureFile.file_created = Date.now();
    editedPictureFile.file_uploaded = false;
    this.entityManager.pictureFileRepository.update(editedPictureFile);

    void this.saveDataUrl(editedPictureFile, dataUrl);
  }

  public async copyPictureFileFile(
    sourcePictureFile: PictureFile,
    destinationPictureFile: PictureFile
  ): Promise<void> {
    const sourceFile =
      await this.pictureFilePathService.getPictureFileSource(sourcePictureFile);
    assertNotNullOrUndefined(sourceFile, 'source picture file has no source');

    destinationPictureFile.file_created = Date.now();
    destinationPictureFile.file_uploaded = false;
    this.entityManager.pictureFileRepository.update(destinationPictureFile);

    const dataUrl = await ImageHelper.loadImageAsDataUrl(sourceFile);

    void this.saveDataUrl(destinationPictureFile, dataUrl);
  }

  private async saveDataUrl(
    pictureFile: PictureFile,
    dataUrl: string,
    showDialogs: boolean = true
  ): Promise<void> {
    const ext = ImageHelper.getFileExtensionForDataUrl(dataUrl);
    if (ext) {
      pictureFile.file_extension = ext as PictureFileExtension;
      this.entityManager.pictureFileRepository.update(pictureFile);
    }

    if (DeviceInfoHelper.isApp()) {
      assertNotNullOrUndefined(
        pictureFile.file_created,
        "can't get the path of a file which hasn't been created yet"
      );

      const pictureFileName = PictureFileNameUtils.getPictureFileName({
        id: pictureFile.id,
        file_created: pictureFile.file_created,
        file_extension: pictureFile.file_extension
      });

      await FileUtils.writeBase64ToLocalFile(
        PathUtils.joinPaths(
          UrlManager.localFolder,
          'captured_pictures',
          pictureFileName
        ),
        dataUrl.replace(/^data:(.+)?;base64,/, '')
      );

      pictureFile.isOriginatingHere = true;
      pictureFile.local_created = pictureFile.file_created;
      pictureFile.readyForUpload = true;
      pictureFile.readyToSynchronize = true;
      this.entityManager.pictureFileRepository.update(pictureFile);

      if (!pictureFile.onlyLocal) {
        this.pictureFileUploadService.submitToUpload(
          pictureFile,
          null,
          this.pictureFilePathService.getCapturedLocalPicPath({ pictureFile }),
          showDialogs
        );
      }
    } else {
      this.pictureFileUploadService.submitToUpload(
        pictureFile,
        dataUrl,
        null,
        showDialogs
      );
    }
  }
}
