import { inject, observable } from 'aurelia-framework';

import { AuthenticationService } from '../../services/AuthenticationService';

import { Utils } from '../../classes/Utils/Utils';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { NotificationHelper } from '../../classes/NotificationHelper';

@inject(AuthenticationService)
export class reset_password {
  /**
   * can be enterPassword, enterToken
   *
   * @type {string}
   * @private
   */
  _mode = 'enterToken';
  /** @type {string} */
  @observable _password;
  /** @type {string} */
  @observable _confirmationPassword;
  _token = '';
  _showPassword = false;

  _loading = false;

  _passwordInputErrorText = '';
  _confirmationPasswordInputErrorText = '';

  /**
   *
   * @param {AuthenticationService} authenticationService
   */
  constructor(authenticationService) {
    this._authenticationService = authenticationService;
    this._isApp = DeviceInfoHelper.isApp() || true;
    this._setMode('enterToken');

    this._password = '';
    this._confirmationPassword = '';
  }

  activate(params) {
    if (params.token) {
      this._token = params.token;
    }
  }

  attached() {
    if (this._token && this._mode != 'enterPassword') {
      this._setMode('enterPassword');
    }
  }

  _setMode(mode) {
    this._mode = mode;
    this._password = '';
    this._confirmationPassword = '';
    this._showPassword = false;
    this._loading = false;
  }

  _handleToggleShowPasswordClick() {
    this._showPassword = !this._showPassword;
  }

  _handleSetTokenFormSubmit() {
    if (this._token.trim() == '') {
      NotificationHelper.notifyDanger('Rücksetzcode muss ausgefüllt werden!');
    } else {
      this._setMode('enterPassword');
    }
  }

  async _handleSetPasswordFormSubmit() {
    if (
      this._password.trim() == '' ||
      this._confirmationPassword.trim() == ''
    ) {
      NotificationHelper.notifyDanger('Alle Felder müssen ausgefüllt sein!');
    } else if (this._password != this._confirmationPassword) {
      NotificationHelper.notifyDanger(
        'Beide Passwörter müssen übereinstimmen!'
      );
    } else {
      try {
        this._loading = true;
        await this._authenticationService.resetPassword(
          this._token,
          this._password,
          this._confirmationPassword,
          { navigate: true }
        );
        this._loading = false;
      } catch (error) {
        this._loading = false;
      }
    }
  }

  _passwordChanged() {
    this._handlePasswordFieldsChanged();
  }

  _confirmationPasswordChanged() {
    this._handlePasswordFieldsChanged();
  }

  _handlePasswordFieldsChanged() {
    this._passwordFieldsAreValid = false;
    if (this._password || this._confirmationPassword) {
      if (!Utils.validatePassword(this._password)) {
        this._passwordInputErrorText =
          'Das Passwort muss aus mind. 8 Zeichen bestehen und mind. 1 Großbuchstaben enthalten!';
        return;
      } else {
        this._passwordInputErrorText = '';
      }
      if (
        this._confirmationPassword &&
        this._password !== this._confirmationPassword
      ) {
        this._confirmationPasswordInputErrorText =
          'Passwörter stimmen nicht überein!';
        return;
      } else {
        this._confirmationPasswordInputErrorText = '';
      }
      this._passwordFieldsAreValid = true;
    }
  }
}
