import {
  PictureFileExtension,
  PictureFileType
} from 'common/Types/Entities/PictureFile/PictureFileDto';
import { EntityName } from '../types';
import { PictureFile } from './types';
import { Picture } from '../Picture/types';
import { DeviceInfoHelper } from '../../../DeviceInfoHelper';
import { AppEntityRepository } from '../../base/AppEntityRepository';

export class PictureFileRepository extends AppEntityRepository<EntityName.PictureFile> {
  public getByPictureIds(pictureIds: Array<string>): Array<PictureFile> {
    return this.getAll().filter((pF) => pictureIds.indexOf(pF.picture) >= 0);
  }

  public getByPictureId(pictureId: string): Array<PictureFile> {
    return this.getAll().filter(
      (pictureFile) => pictureFile.picture === pictureId
    );
  }

  public getPictureFileToDisplayByPictureId(
    pictureId: string
  ): PictureFile | null {
    let pictureFile = this.getEditedPictureFileByPictureId(pictureId);
    if (!pictureFile) {
      pictureFile = this.getOriginalPictureFileByPictureId(pictureId);
    }
    return pictureFile;
  }

  public getEditedPictureFileByPictureId(
    pictureId: string
  ): PictureFile | null {
    return (
      this.getAll().find((pictureFile) => {
        return (
          pictureFile.picture === pictureId &&
          pictureFile.type === PictureFileType.EDITED
        );
      }) ?? null
    );
  }

  public getOriginalPictureFileByPictureId(
    pictureId: string
  ): PictureFile | null {
    return (
      this.getAll().find((pictureFile) => {
        return (
          pictureFile.picture === pictureId &&
          pictureFile.type === PictureFileType.ORIGINAL
        );
      }) ?? null
    );
  }

  public getSketchPictureFileByPictureId(
    pictureId: string
  ): PictureFile | null {
    return (
      this.getAll().find((pictureFile) => {
        return (
          pictureFile.picture === pictureId &&
          pictureFile.type === PictureFileType.SKETCH
        );
      }) ?? null
    );
  }

  public getCroppedPictureFileByPictureId(
    pictureId: string
  ): PictureFile | null {
    return (
      this.getAll().find((pictureFile) => {
        return (
          pictureFile.picture === pictureId &&
          pictureFile.type === PictureFileType.CROPPED
        );
      }) ?? null
    );
  }

  public createPictureFileForPicture(
    picture: Picture,
    type: PictureFileType,
    extension: PictureFileExtension = PictureFileExtension.JPG
  ): PictureFile {
    return this.create({
      picture: picture.id,
      project: picture.project ?? null, // DEPRECATED FIELD
      type: type,
      file_extension: extension,
      ownerUserGroupId: picture.ownerUserGroupId,
      ownerProjectId: picture.ownerProjectId ?? null,
      ownerDefectId: picture.ownerDefectId ?? null,
      ownerProcessConfigurationId: picture.ownerProcessConfigurationId ?? null,
      ownerProcessTaskGroupId: picture.ownerProcessTaskGroupId ?? null,
      readyForUpload: DeviceInfoHelper.isApp() ? false : true, // app images are not instantly ready to upload because there is some file handling to be done in the background
      readyToSynchronize: DeviceInfoHelper.isApp() ? false : true, // pictureFiles in the app shouldn't be synchronized immediately, because the id can be upgraded while a file is still getting written and that would result in missing files because they can't be tracked anymore.
      temporaryGroupName: picture.temporaryGroupName,
      shadowEntity: picture.shadowEntity,
      pictureRevisionId: null
    });
  }
}
