import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskAppointmentEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/processTaskAppointmentEntityInfo';
import { ProcessTaskAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskAppointmentAdapter
  implements EntityAdapter<ProcessTaskAppointment>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private userGroupIdsWithSmsCredentials: Set<string> = new Set();

  constructor(options: ProcessTaskAppointmentAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ userGroupIdsWithSmsCredentials }) => {
          this.userGroupIdsWithSmsCredentials = userGroupIdsWithSmsCredentials;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public canDeleteEntity(appointment: ProcessTaskAppointment): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanDeleteProcessTaskAppointments();
  }

  public canEditField(appointment: ProcessTaskAppointment): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanUpdateProcessTaskAppointments();
  }

  public canEditProperties(appointment: ProcessTaskAppointment): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanUpdateProcessTaskAppointments();
  }

  public canEditGeneralFiles(appointment: ProcessTaskAppointment): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanUpdateProcessTaskAppointments();
  }

  public canSeeSmsNotificationSetting(
    appointment: ProcessTaskAppointment
  ): boolean {
    return this.userGroupIdsWithSmsCredentials.has(
      appointment.ownerUserGroupId
    );
  }

  public canCreateProcessTaskAppointmentContacts(
    appointment: ProcessTaskAppointment
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanCreateProcessTaskAppointmentContacts();
  }

  public canCreateProcessTaskAppointmentToUsers(
    appointment: ProcessTaskAppointment
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanCreateProcessTaskAppointmentToUsers();
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskAppointment,
    ProcessTaskAppointment
  > {
    return processTaskAppointmentEntityInfo;
  }
}

export type ProcessTaskAppointmentAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
