import { OptionalProperties, SetPropertiesToType } from '../../utilities';
import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';

type BaseData<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> & {
  name: string | null;
  created: number;
  status: ExportStatus | null;
  uploadedToExternalLocationStatus: UploadedStatus | null;

  /** @deprecated */
  file: string | null;

  type: ExportType;
  exported: number | null;
  signatureTime: TDate | null;
  signatureUserId: TId | null;
  file_size: number | null;
  report_type: TId | null;
};

type IdFields<TId extends IdType> = UserGroupSubEntity<TId> & {
  project: TId;
  thing: TId;
  ownerProjectId: TId;
};

type EntitySpecificReportDto<
  TId extends IdType,
  TDate extends DateType,
  TEntityIds extends keyof IdFields<TId>,
  TOptionalBaseDataProperties extends keyof BaseData<TId, TDate>,
  TPropertiesToRemove extends keyof BaseData<TId, TDate>
> = Omit<
  OptionalProperties<BaseData<TId, TDate>, TOptionalBaseDataProperties>,
  TPropertiesToRemove
> &
  Pick<IdFields<TId>, TEntityIds | 'ownerUserGroupId'> &
  Partial<
    SetPropertiesToType<
      Omit<IdFields<TId>, TEntityIds | 'ownerUserGroupId'>,
      null
    >
  >;

export type ThingReportDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof BaseData<TId, TDate> = never,
  TPropertiesToRemove extends keyof BaseData<TId, TDate> = never
> = EntitySpecificReportDto<
  TId,
  TDate,
  'thing',
  TOptionalBaseDataProperties,
  TPropertiesToRemove
>;

export type ProjectReportDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof BaseData<TId, TDate> = never,
  TPropertiesToRemove extends keyof BaseData<TId, TDate> = never
> = EntitySpecificReportDto<
  TId,
  TDate,
  'project' | 'ownerProjectId',
  TOptionalBaseDataProperties,
  TPropertiesToRemove
>;

export type ReportDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof BaseData<TId, TDate> = never,
  TPropertiesToRemove extends keyof BaseData<TId, TDate> = never
> =
  | ThingReportDto<TId, TDate, TOptionalBaseDataProperties, TPropertiesToRemove>
  | ProjectReportDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TPropertiesToRemove
    >;

export enum ExportType {
  DOCX = 'docx',
  PDF = 'pdf',
  ZIP = 'zip',
  LATEST_REPORTS_ZIP = 'latestReportsZip'
}

export enum ExportStatus {
  EXPORTING = 'exporting',
  CONVERTING = 'converting',
  ZIPPING = 'zipping',
  READY = 'ready',
  FAILED = 'failed'
}

export enum UploadedStatus {
  UPLOADED = 'uploaded'
}
