/*
  THIS FILE IS AUTOMATICALLY CREATED BY A GENERATION SCRIPT.
  Only changes to canCreateFieldName, canUpdateFieldName and canDeleteFieldName will be persisted.
*/

import { EntityName } from '../../Types/BaseEntities/EntityName';
import { validateEntityNameToRoleInfo } from './validateEntityNameToRoleInfo';

export const entityNameToRoleInfo = validateEntityNameToRoleInfo({
  roleInfos: {
    [EntityName.Defect]: {
      canCreateFieldName: 'canCreateDefects',
      canUpdateFieldName: 'canUpdateDefects',
      canDeleteFieldName: 'canDeleteDefects'
    },
    [EntityName.DefectChangeLogEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.DefectComment]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.EntityDashboardInfo]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Entry]: {
      canCreateFieldName: 'canCreateEntries',
      canUpdateFieldName: 'canUpdateEntries',
      canDeleteFieldName: 'canDeleteEntries'
    },
    [EntityName.EntryToPerson]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.EntryUsageStatistic]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ExternalUserCredential]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.GeneralFile]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.MapLayer]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.NfcToken]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.NfcTokenToPerson]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.NfcTokenToThing]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Person]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PersonContact]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PersonToPerson]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Picture]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PictureFile]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PictureRevision]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessConfiguration]: {
      canCreateFieldName: 'canCreateProcessConfigurations',
      canUpdateFieldName: 'canUpdateProcessConfigurations',
      canDeleteFieldName: 'canDeleteProcessConfigurations'
    },
    [EntityName.ProcessConfigurationActionStatus]: {
      canCreateFieldName: 'canCreateProcessConfigurationActionStates',
      canUpdateFieldName: 'canUpdateProcessConfigurationActionStates',
      canDeleteFieldName: 'canDeleteProcessConfigurationActionStates'
    },
    [EntityName.ProcessConfigurationAuthorizationType]: {
      canCreateFieldName: 'canCreateProcessConfigurationAuthorizationTypes',
      canUpdateFieldName: 'canUpdateProcessConfigurationAuthorizationTypes',
      canDeleteFieldName: 'canDeleteProcessConfigurationAuthorizationTypes'
    },
    [EntityName.ProcessConfigurationCategory]: {
      canCreateFieldName: 'canCreateProcessConfigurationCategories',
      canUpdateFieldName: 'canUpdateProcessConfigurationCategories',
      canDeleteFieldName: 'canDeleteProcessConfigurationCategories'
    },
    [EntityName.ProcessConfigurationDevice]: {
      canCreateFieldName: 'canCreateProcessConfigurationDevices',
      canUpdateFieldName: 'canUpdateProcessConfigurationDevices',
      canDeleteFieldName: 'canDeleteProcessConfigurationDevices'
    },
    [EntityName.ProcessConfigurationDeviceExport]: {
      canCreateFieldName: 'canCreateProcessConfigurationDeviceExports',
      canUpdateFieldName: 'canUpdateProcessConfigurationDeviceExports',
      canDeleteFieldName: 'canDeleteProcessConfigurationDeviceExports'
    },
    [EntityName.ProcessConfigurationFollowUpAppointment]: {
      canCreateFieldName: 'canCreateProcessConfigurationFollowUpAppointments',
      canUpdateFieldName: 'canUpdateProcessConfigurationFollowUpAppointments',
      canDeleteFieldName: 'canDeleteProcessConfigurationFollowUpAppointments'
    },
    [EntityName.ProcessConfigurationForm]: {
      canCreateFieldName: 'canCreateProcessConfigurationForms',
      canUpdateFieldName: 'canUpdateProcessConfigurationForms',
      canDeleteFieldName: 'canDeleteProcessConfigurationForms'
    },
    [EntityName.ProcessConfigurationFormSendTarget]: {
      canCreateFieldName: 'canCreateProcessConfigurationFormSendTargets',
      canUpdateFieldName: 'canUpdateProcessConfigurationFormSendTargets',
      canDeleteFieldName: 'canDeleteProcessConfigurationFormSendTargets'
    },
    [EntityName.ProcessConfigurationMeasurePointType]: {
      canCreateFieldName: 'canCreateProcessConfigurationMeasurePointTypes',
      canUpdateFieldName: 'canUpdateProcessConfigurationMeasurePointTypes',
      canDeleteFieldName: 'canDeleteProcessConfigurationMeasurePointTypes'
    },
    [EntityName.ProcessConfigurationStep]: {
      canCreateFieldName: 'canCreateProcessConfigurationSteps',
      canUpdateFieldName: 'canUpdateProcessConfigurationSteps',
      canDeleteFieldName: 'canDeleteProcessConfigurationSteps'
    },
    [EntityName.ProcessConfigurationStepAutoAppointment]: {
      canCreateFieldName: 'canCreateProcessConfigurationStepAutoAppointments',
      canUpdateFieldName: 'canUpdateProcessConfigurationStepAutoAppointments',
      canDeleteFieldName: 'canDeleteProcessConfigurationStepAutoAppointments'
    },
    [EntityName.ProcessConfigurationStepAutoForm]: {
      canCreateFieldName: 'canCreateProcessConfigurationStepAutoForms',
      canUpdateFieldName: 'canUpdateProcessConfigurationStepAutoForms',
      canDeleteFieldName: 'canDeleteProcessConfigurationStepAutoForms'
    },
    [EntityName.ProcessConfigurationStepPosition]: {
      canCreateFieldName: 'canCreateProcessConfigurationStepPositions',
      canUpdateFieldName: 'canUpdateProcessConfigurationStepPositions',
      canDeleteFieldName: 'canDeleteProcessConfigurationStepPositions'
    },
    [EntityName.ProcessTask]: {
      canCreateFieldName: 'canCreateProcessTasks',
      canUpdateFieldName: 'canUpdateProcessTasks',
      canDeleteFieldName: 'canDeleteProcessTasks'
    },
    [EntityName.ProcessTaskAppointment]: {
      canCreateFieldName: 'canCreateProcessTaskAppointments',
      canUpdateFieldName: 'canUpdateProcessTaskAppointments',
      canDeleteFieldName: 'canDeleteProcessTaskAppointments'
    },
    [EntityName.ProcessTaskAppointmentContact]: {
      canCreateFieldName: 'canCreateProcessTaskAppointmentContacts',
      canUpdateFieldName: 'canUpdateProcessTaskAppointmentContacts',
      canDeleteFieldName: 'canDeleteProcessTaskAppointmentContacts'
    },
    [EntityName.ProcessTaskAppointmentToProcessTaskDevice]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskAppointmentToProcessTaskPosition]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskAppointmentToUser]: {
      canCreateFieldName: 'canCreateProcessTaskAppointmentToUsers',
      canUpdateFieldName: 'canUpdateProcessTaskAppointmentToUsers',
      canDeleteFieldName: 'canDeleteProcessTaskAppointmentToUsers'
    },
    [EntityName.ProcessTaskChecklistEntry]: {
      canCreateFieldName: 'canCreateProcessTaskChecklistEntries',
      canUpdateFieldName: 'canUpdateProcessTaskChecklistEntries',
      canDeleteFieldName: 'canDeleteProcessTaskChecklistEntries'
    },
    [EntityName.ProcessTaskComment]: {
      canCreateFieldName: 'canCreateProcessTaskComments',
      canUpdateFieldName: 'canUpdateProcessTaskComments',
      canDeleteFieldName: 'canDeleteProcessTaskComments'
    },
    [EntityName.ProcessTaskDevice]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskGroup]: {
      canCreateFieldName: 'canCreateProcessTaskGroups',
      canUpdateFieldName: 'canUpdateProcessTaskGroups',
      canDeleteFieldName: 'canDeleteProcessTaskGroups'
    },
    [EntityName.ProcessTaskGroupAuthorization]: {
      canCreateFieldName: 'canCreateProcessTaskGroupAuthorizations',
      canUpdateFieldName: 'canUpdateProcessTaskGroupAuthorizations',
      canDeleteFieldName: 'canDeleteProcessTaskGroupAuthorizations'
    },
    [EntityName.ProcessTaskGroupFilter]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskInvoice]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskInvoiceToProcessTask]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskInvoiceToProcessTaskDevice]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskInvoiceToProcessTaskPosition]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskLogEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskMeasurePoint]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskMeasurePointReading]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskMeasurePointToPicture]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskOffer]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskOfferToProcessTask]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskOfferToProcessTaskDevice]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskOfferToProcessTaskPosition]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskPosition]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskPositionDetailEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskRecurringAppointment]: {
      canCreateFieldName: 'canCreateProcessTaskRecurringAppointments',
      canUpdateFieldName: 'canUpdateProcessTaskRecurringAppointments',
      canDeleteFieldName: 'canDeleteProcessTaskRecurringAppointments'
    },
    [EntityName.ProcessTaskToProject]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Project]: {
      canCreateFieldName: 'canCreateProjects',
      canUpdateFieldName: 'canUpdateProjects',
      canDeleteFieldName: 'canDeleteProjects'
    },
    [EntityName.ProjectQuestion]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProjectQuestionCatalogue]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProjectQuestionCatalogueToQuestionSet]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProjectQuestionCategory]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProjectQuestionSet]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Property]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PropertyToPerson]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PropertyToProcessTaskPosition]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Question]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionCatalogue]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionCatalogueToQuestionSet]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionCatalogueToThing]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionCategory]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionSet]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Region]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Report]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ReportType]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.SharepointListColumnToThingMappingItem]: {
      canCreateFieldName: 'canCreateSharepointListColumnToThingMappingItems',
      canUpdateFieldName: 'canUpdateSharepointListColumnToThingMappingItems',
      canDeleteFieldName: 'canDeleteSharepointListColumnToThingMappingItems'
    },
    [EntityName.StructurePictureArea]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplate]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplateEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplateEntryGroup]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplateEntryGroupToStructureTemplateEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplateEntryProperty]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplateRatingCategory]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Tag]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.TextBrick]: {
      canCreateFieldName: 'canCreateTextBricks',
      canUpdateFieldName: 'canUpdateTextBricks',
      canDeleteFieldName: 'canDeleteTextBricks'
    },
    [EntityName.TextBrickTemplate]: {
      canCreateFieldName: 'canCreateTextBrickTemplates',
      canUpdateFieldName: 'canUpdateTextBrickTemplates',
      canDeleteFieldName: 'canDeleteTextBrickTemplates'
    },
    [EntityName.Thing]: {
      canCreateFieldName: 'canCreateThings',
      canUpdateFieldName: 'canUpdateThings',
      canDeleteFieldName: 'canDeleteThings'
    },
    [EntityName.ThingAuthorization]: {
      canCreateFieldName: 'canCreateThingAuthorizations',
      canUpdateFieldName: 'canUpdateThingAuthorizations',
      canDeleteFieldName: 'canDeleteThingAuthorizations'
    },
    [EntityName.ThingGroup]: {
      canCreateFieldName: 'canCreateThingGroups',
      canUpdateFieldName: 'canUpdateThingGroups',
      canDeleteFieldName: 'canDeleteThingGroups'
    },
    [EntityName.ThingSection]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ThingSectionConfigProperty]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ThingToPerson]: {
      canCreateFieldName: 'canCreateThingToPersons',
      canUpdateFieldName: 'canUpdateThingToPersons',
      canDeleteFieldName: 'canDeleteThingToPersons'
    },
    [EntityName.ThingType]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.User]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserCompanySetting]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserDefinedEntity]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserDefinedEntityConfig]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserDefinedEntityConfigPropertyConfig]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserGroup]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserRole]: {
      canCreateFieldName: 'canCreateUserRoles',
      canUpdateFieldName: 'canUpdateUserRoles',
      canDeleteFieldName: 'canDeleteUserRoles'
    },
    [EntityName.UserRoleToUser]: {
      canCreateFieldName: 'canCreateUserRoleToUsers',
      canUpdateFieldName: 'canUpdateUserRoleToUsers',
      canDeleteFieldName: 'canDeleteUserRoleToUsers'
    },
    [EntityName.ValueCalculationConfig]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ValueCalculationResult]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    }
  }
});

export type EntityNameToRoleInfo = typeof entityNameToRoleInfo;
