import { autoinject } from 'aurelia-framework';
import { MomentInput } from 'moment';

import { DateUtils } from '../../../../common/src/DateUtils';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { CoordinateHelper, Coordinates } from '../../classes/CoordinateHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';

/**
 * this is meant to be a single global instance
 */
@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProjectDialog {
  private project: Project | null = null;

  private onDialogClosed: ((project: Project) => void) | null = null;

  private dialog: RecordItDialog | null = null;

  private clientCoordinates: Coordinates | null = null;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  private permissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle = permissionsService.getPermissionsHandleForEntity({
      entityName: EntityName.Project,
      entity: null
    });
  }

  /**
   * @param {TEditProjectDialogOptions} options
   */
  public open(options: TEditProjectDialogOptions): void {
    this.project = options.project;

    setTimeout(() => {
      this.permissionsHandle =
        this.permissionsService.getPermissionsHandleForEntity({
          entityName: EntityName.Project,
          entity: options.project
        });
    }, 200);

    this.onDialogClosed = options.onDialogClosed ?? null;

    this.clientCoordinates = CoordinateHelper.getClientCoordinates();

    if (this.dialog) this.dialog.open();
  }

  public close(): void {
    if (this.dialog) this.dialog.close();
  }

  private handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed;
    const project = this.project;

    this.project = null;
    this.clientCoordinates = null;
    this.onDialogClosed = null;
    this.subscriptionManager.disposeSubscriptions();

    if (project) {
      onDialogClosed?.(project);
    }
  }

  private handleProjectChanged(): void {
    if (this.project) this.entityManager.projectRepository.update(this.project);
  }

  private formatToDateString(date: MomentInput): string {
    return DateUtils.formatToDateString(date);
  }

  private canCalculateDistance(
    latitude: number | null,
    longitude: number | null,
    clientLatitude: number | null,
    clientLongitude: number | null
  ): boolean {
    return (
      this.isNumber(latitude) &&
      this.isNumber(longitude) &&
      this.isNumber(clientLatitude) &&
      this.isNumber(clientLongitude)
    );
  }

  private calculateDistance(
    latitude: number,
    longitude: number,
    clientLatitude: number,
    clientLongitude: number
  ): string {
    return CoordinateHelper.calculateFormattedDistance(
      clientLongitude,
      clientLatitude,
      longitude,
      latitude
    );
  }

  private isNumber(i: any): boolean {
    return !isNaN(parseFloat(i));
  }

  public static async open(options: TEditProjectDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

export interface TEditProjectDialogOptions {
  project: Project;
  onDialogClosed?: ((project: Project) => void) | null;
}
