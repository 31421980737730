import { PersonCategories } from 'common/Types/PersonCategories';
import { EntityInfo } from '@record-it-npm/synchro-common';
import { personEntityInfo } from '../../../../classes/EntityManager/entities/Person/personEntityInfo';
import { Person } from '../../../../classes/EntityManager/entities/Person/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';

export class PersonAdapter implements EntityAdapter<Person> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;

  private editableUserGroupIds = new Set<string>();
  private groupAdminUserGroupIds = new Set<string>();
  private groupAdminOnlyPersonCategoryNames = new Set<string>();

  constructor(options: PersonAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds, groupAdminUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          this.groupAdminUserGroupIds = groupAdminUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.personCategories',
        (personCategories: PersonCategories | null) => {
          const groupAdminOnlyPersonCategoryNames = new Set<string>();

          if (personCategories) {
            for (const category of personCategories) {
              if (category.onlyEditableByGroupAdmins) {
                groupAdminOnlyPersonCategoryNames.add(category.value);
              }
            }
          }

          this.groupAdminOnlyPersonCategoryNames =
            groupAdminOnlyPersonCategoryNames;
          updateBindings();
        }
      )
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canEditField(entity: Person): boolean {
    return this.entityIsEditableDefault(entity);
  }

  public canDeleteEntity(entity: Person): boolean {
    return this.entityIsEditableDefault(entity);
  }

  public canManageContacts(entity: Person): boolean {
    return this.entityIsEditableDefault(entity);
  }

  public canManageRelations(entity: Person): boolean {
    return this.entityIsEditableDefault(entity);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName,
    Person
  > {
    return personEntityInfo;
  }

  private entityIsEditableDefault(entity: Person): boolean {
    if (
      entity.categoryName &&
      this.groupAdminOnlyPersonCategoryNames.has(entity.categoryName)
    ) {
      return this.groupAdminUserGroupIds.has(entity.ownerUserGroupId);
    }

    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type PersonAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
};
